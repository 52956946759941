import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
    Colours,
    toKebabCase,
} from '@ratehub/base-ui';

import getSafeHTMLName from '../functions/getSafeHTMLName';
import { MENU_PADDING } from './LinkBlockAnchor';
import CollapsibleContainerButton from './CollapsibleContainerButton';


function CollapsibleContainer({
    title,

    isInitiallyExpanded = true,
    isExpanded: controlledIsExpanded,
    onClick,

    as = 'div',
    children,

    ...otherProps
}) {
    const [ isExpanded, setIsExpanded ] = useState(isInitiallyExpanded);

    const id = `collapsible-container-${getSafeHTMLName(toKebabCase(title))}`;
    const label = `label-${getSafeHTMLName(toKebabCase(title))}`;

    const isControlled = controlledIsExpanded != null;

    // Keep the controlled state and internal state in sync
    useEffect(() => {
        if (isControlled) {
            setIsExpanded(controlledIsExpanded);
        }
    }, [ controlledIsExpanded ]);

    function handleContainerClick() {
        onClick?.();

        // If we're not controlled, we can update our internal state
        if (!isControlled) {
            setIsExpanded(!isExpanded);
        }
    }

    return (
        <Container
            id={id}
            isExpanded={isExpanded}
            aria-labelledby={label}
            as={as}
            {...otherProps}
        >
            <h3
                className="heading"
                id={label}
            >
                <CollapsibleContainerButton
                    className="toggleButton"
                    onClick={handleContainerClick}
                    isExpanded={isExpanded}
                    data-name="collapsibleContainer-toggle" // for Analytics and tests
                    aria-controls={id}
                    aria-expanded={isExpanded}
                >
                    {title}
                </CollapsibleContainerButton>
            </h3>

            <div className="menuContainer">
                <hr className="separator" />

                {children}
            </div>
        </Container>
    );
}

CollapsibleContainer.propTypes = {
    title: PropTypes.string.isRequired,

    isInitiallyExpanded: PropTypes.bool,
    isExpanded: PropTypes.bool,
    onClick: PropTypes.func,

    as: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    children: PropTypes.node,
};

const Container = styled.div`
    > .heading {
        position: relative;
        margin: 0 ${MENU_PADDING};
    }

    > .menuContainer {
        display: ${props => (props.isExpanded === true ? 'block' : 'none')};
        padding: 0 0 ${MENU_PADDING};

        > .separator {
            height: 1px;
            border: 0;
            background-color: ${Colours.STONE};
            margin: 0 ${MENU_PADDING};
        }
    }
`;

export default CollapsibleContainer;
