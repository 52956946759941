import React from 'react';
import PropTypes from 'prop-types';


/**
 * Wordpress core block which is used for HTML insertion.
 */
function WordpressCoreCustomHTML({ content, className }) {
    return (
        <div
            className={className}

            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
                __html: content,
            }}
        />
    );
}

WordpressCoreCustomHTML.propTypes = {
    content: PropTypes.string.isRequired,
    className: PropTypes.string,
};

WordpressCoreCustomHTML.blockKey = 'core/html';
WordpressCoreCustomHTML.requiresLayoutRow = true;

export default WordpressCoreCustomHTML;

