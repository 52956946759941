import React, { useEffect, useRef } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';

import {
    PrimaryButton,
    Form,
    Config,
    isCMS,
    Select,
    ZIndex,
} from '@ratehub/base-ui';
import { INSURANCE_TYPES } from '@ratehub/ins-common';

import getProvinceOptions from '../functions/getProvinceOptions';
import PAGE_TEMPLATES from '../definitions/PageTemplates';

import useHomeInsuranceQuoteLauncher from '../hooks/useHomeInsuranceQuoteLauncher';
import CommonMessages from '../definitions/CommonMessages';


const SQUARE_ONE_URL = new URL(Config.SQUARE_ONE_IFRAME_URL);

function HomeInsuranceQuoteLauncher({
    ctaLabel,
    fieldId,
    providerRedirects,
}) {
    const state = useHomeInsuranceQuoteLauncher(providerRedirects);
    const squareOneIFrameRef = useRef(); // ref to be used with sq1 iframe

    // Resize the height of the SquareOne widget if it's being displayed.
    useEffect(() => {
        const iframeElement = squareOneIFrameRef.current;
        if (!iframeElement && state.isSquareOneWidgetVisible) {
            return;
        }

        // ASSUMPTION: we are assuming that the only mesage which is getting raised is a height change event.
        // sq1 iframe emits 'message' events to us with a height value for the iframe
        function handleHeightChange(event) {
            if (iframeElement && event.origin === SQUARE_ONE_URL.origin && typeof event.data === 'number') {
                iframeElement.style.height = event.data + 'px';
            }
        }

        // NOTE: the iframe is posting messages DIRECTLY to our window (they will have their .origin property match the URL)
        window.addEventListener('message', handleHeightChange, false);

        return () => window.removeEventListener('message', handleHeightChange, false);
    }, [ state.isSquareOneWidgetVisible, squareOneIFrameRef.current ]);

    return (
        <>
            <StyledForm
                className="rh-display-flex rh-flex-wrap rh-gap-1 rh-align-items-flex-start rh-flex-grow-1"
                onSubmit={state.handleSubmit}
                onChange={state.handleFieldChange}
                data-name="home-insurance-quote-start-form"
            >
                <Select
                    id={`insuranceTypeField-${fieldId}`}
                    className="form-field"
                    name="insuranceTypeField"
                    options={TYPE_OPTIONS}
                    label={<FormattedMessage {...CommonMessages.insuranceType} />}
                />

                <Select
                    id={`provinceField-${fieldId}`}
                    className="form-field"
                    name="provinceField"
                    options={getProvinceOptions(providerRedirects)}
                    label={<FormattedMessage {...CommonMessages.province} />}
                    canShowInvalid={state.doNotService}
                    onValidate={() => state.doNotService ? MESSAGES.INVALID_COMBINATION : true}
                />

                <PrimaryButton
                    className="submit form-field"
                    data-name="home-quoteLauncher-submit-cta"
                    message={ctaLabel}
                    type="submit"
                    disabled={state.isSquareOneWidgetVisible || state.doNotService}
                />
            </StyledForm>

            {/* We don't want this to mount in Wordpress, hence the window.wp check */}
            <If condition={state.isSquareOneWidgetVisible && !isCMS()}>
                <StyledWidgetContainer className="rh-mt-1 rh-mx-auto rh-bg-coconut">
                    <iframe
                        title="SquareOne"
                        ref={squareOneIFrameRef}
                        seamless=""
                        width="100%"
                        frameBorder="0"
                        src={SQUARE_ONE_URL.href}
                    />
                </StyledWidgetContainer>
            </If>
        </>
    );
}

HomeInsuranceQuoteLauncher.propTypes = {
    ctaLabel: PropTypes.string.isRequired,
    fieldId: PropTypes.string.isRequired,
    providerRedirects: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.shape({
        slug: PropTypes.string,
        redirectUrl: PropTypes.string,
        regions: PropTypes.object, // ex: ON: 100
    }))).isRequired,
};

const StyledForm = styled(Form)`
    /* Need to use a direct style here and not the .rh-zindex-drop-downs classname
        because LayoutRownColumn is overriding it with ZIndex.ELEMENTS */
    &&& {
        z-index: ${ZIndex.DROP_DOWNS};
    }

    .form-field {
        flex: 1 0 200px;
    }
`;

const StyledWidgetContainer = styled.div`
    min-width: 200px;
    width: 66%;

    /* Mobile breakpoint */
    @media (max-width: 43em) {
        width: 100%;
    }
`;

const MESSAGES = defineMessages({
    INVALID_COMBINATION: {
        id: 'insurance.inlineForm.nothingForThisProvince',
        defaultMessage: 'Sorry, we don\'t service this region',
    },
    SELECT_HOME: {
        id: 'insurance.homeInsuranceQuoteLauncher.home',
        defaultMessage: 'Home',
    },
    SELECT_CONDO: {
        id: 'insurance.homeInsuranceQuoteLauncher.condo',
        defaultMessage: 'Condo',
    },
    SELECT_TENANT: {
        id: 'insurance.homeInsuranceQuoteLauncher.tenant',
        defaultMessage: 'Tenant',
    },
});

const TYPE_OPTIONS = [
    {
        label: MESSAGES.SELECT_HOME,
        value: INSURANCE_TYPES.HOME,
    },
    {
        label: MESSAGES.SELECT_CONDO,
        value: INSURANCE_TYPES.CONDO,
    },
    {
        label: MESSAGES.SELECT_TENANT,
        value: INSURANCE_TYPES.TENANT,
    },
];


HomeInsuranceQuoteLauncher.blockKey = 'rh/home-ins-inline-form';
HomeInsuranceQuoteLauncher.supportedPageTypes = [ PAGE_TEMPLATES.insurance ];

export default observer(HomeInsuranceQuoteLauncher);
