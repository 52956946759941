import { useState, useEffect, useCallback } from 'react';

import { PROVINCE, getProvincePrincipalCity, useIsMounted, useSession, getCityFromLocation, noticeError } from '@ratehub/base-ui';

import { useIntl } from 'react-intl';
import enrichAllProductGroups from '../functions/enrichAllProductGroups';


const DEFAULT_PROVINCE = PROVINCE.ONTARIO;

// TODO: Do we even need this hook or can it be simplified? https://ratehub.atlassian.net/browse/FIT-3938
function useFeaturedProducts({ verifyLocation, productGroups, hideCTAIfNotMonetized }) {
    const { locale } = useIntl();
    const sessionStore = useSession();

    const getIsMounted = useIsMounted();
    const [ city, setCity ] = useState(() => getProvincePrincipalCity(DEFAULT_PROVINCE));
    const [ isLocationVerified, setIsLocationVerified ] = useState(!verifyLocation); // Default to true if we're not asked to verify location

    const fetchLocationAndProductGroups = useCallback(
        async () => {
            const userCity = await fetchLocation(sessionStore);

            // only re-fetch and enrich if province changes
            if (userCity.province.code !== city.province.code) {
            // NOTE: This does a mutation to productGroups which is
            //  why this isn't returned or assigned to any variables
                try {
                    await enrichAllProductGroups(productGroups, {
                        locale,
                        province: userCity.province.code,
                        hideCTAIfNotMonetized,
                    });
                } catch (e) {
                    // Error noticed within enrichAllProductGroups
                    // TEMPORARY: Adding noticeError here for visibility for CircleSpinner issue to see if its tied to the long spinner
                    noticeError(e, {
                        message: '[useFeaturedProducts] enrichAllProductGroups failed',
                    });
                }
            }

            return userCity;
        },
        [ sessionStore, JSON.stringify(city) ]
    );

    // If we're asked to verify location, fetch the user's location and refresh product groups if necessary
    useEffect(() => {
        if (!verifyLocation) {
            return;
        }

        fetchLocationAndProductGroups().then((updatedCity) => {
            if (!getIsMounted()) {
                return;
            }

            setCity(updatedCity);
            setIsLocationVerified(true);
        });
    }, [ verifyLocation, fetchLocationAndProductGroups ]);

    return {
        // loading state
        isLocationVerified,

        // data
        city,
        get provinceCode() {
            return this.city.province.code;
        },
    };
}

async function fetchLocation(sessionStore) {
    try {
        await sessionStore.fetchSession();

        return getCityFromLocation(sessionStore.interestLocation) ?? getProvincePrincipalCity(DEFAULT_PROVINCE);
    } catch (e) {
        // TEMPORARY: Adding noticeError here for visibility for CircleSpinner issue to see if this is tied to the long spinner
        noticeError(e, {
            message: '[useFeaturedProducts] fetchLocation failed',
        });
        return getProvincePrincipalCity(DEFAULT_PROVINCE);
    }
}

export default useFeaturedProducts;