import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import HeadingPropTypes from '../definitions/HeadingPropTypes';
import HeadingBlock from './HeadingBlock';


function NavigationListContainer({ heading, children, ...otherProps }) {
    return (
        <>
            <HeadingBlock
                {...heading}
            />

            <NavigationListContainer.Container {...otherProps}>
                {children}
            </NavigationListContainer.Container>
        </>
    );
}

NavigationListContainer.propTypes = {
    heading: HeadingPropTypes,
    children: PropTypes.any,
};

NavigationListContainer.Container = styled.ul`
    margin: 0 auto;
    padding: 0;
    max-width: 900px;
`;

NavigationListContainer.blockKey = 'rh/navigation-list-container';
NavigationListContainer.hasHeading = true;
NavigationListContainer.requiresLayoutRow = true;

export default NavigationListContainer;
