import React, { Suspense, useRef, useState, useEffect } from 'react';

import getComponentDisplayName from '../functions/getComponentDisplayName';
import CircleSpinner from './CircleSpinner';


// Ideally LazyComponent would be using React.lazy so it aligns with our Suspense usage
function withSuspenseIntersectionObserver(LazyComponent, {
    containerAs = 'div', 

    root, 
    rootMargin, 
    threshold = 0,
} = {}) {
    function WrapperComponent(componentProps) {
        const containerRef = useRef();
        const [ hasIntesected, setHasIntersected ] = useState(false);

        // Once the ref is set up, we want to create an IntersectionObserver
        //  on the container element to track when its in view.
        //  When the container is in view, we can render our LazyComponent
        useEffect(() => {
            const targetElement = containerRef?.current;

            if (!targetElement) {
                return;
            }
            
            const observer = new IntersectionObserver(
                ([ entry ]) => {
                    if (entry.isIntersecting) {
                        setHasIntersected(true);
                    }
                },
                {
                    root,
                    rootMargin,
                    threshold,
                },
            );

            observer.observe(targetElement);

            return () => observer.disconnect();
        }, [ containerRef?.current ]);

        const ContainerElement = containerAs;

        return (
            <Suspense fallback={(
                <CircleSpinner
                    // CircleSpinner has a timeout if its up for too long and since we're using an 
                    //  IntersectionObserver, there's a chance the user has a page with this component open 
                    //  for longer than the CircleSpinner duration before they scroll to this component. 
                    //  We want to start the timer only when the component is visible in the viewport.
                    isTimerActive={hasIntesected}
                />
            )}
            >
                <ContainerElement ref={containerRef}>
                    <If condition={hasIntesected}>
                        <LazyComponent {...componentProps} />
                    </If>
                </ContainerElement>
            </Suspense>
        );
    }

    WrapperComponent.displayName = `withSuspenseIntersectionObserver(${getComponentDisplayName(LazyComponent)})`;

    return WrapperComponent;
}

export default withSuspenseIntersectionObserver;