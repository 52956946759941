import PropTypes from 'prop-types';


// NOTE:
// Only TabContainer really uses the title, but TabContainer accesses the title
// by rendering a child `Tab` and pulling the `title` prop off of the child
// using React.children().

// eslint-disable-next-line no-unused-vars
function Tab({ title, subTitle, children }) {
    return children;
}

Tab.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    children: PropTypes.any,
};

export default Tab;
