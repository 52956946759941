import { addSearchParams, AFFILIATE_QUERY_PARAM_KEYS, Config, Products } from '@ratehub/base-ui';


function getApplyHrefForBanking(cmsEntry, apiEntry) {
    const cmsURL = cmsEntry.hrefOverride || cmsEntry.applyHref;
    // Crypto products are not fully set up, so we need to match the key in the CMS not from the API
    const apiURL = apiEntry.productType === Products.CRYPTO
        ? apiEntry.apply_url
        : apiEntry.applyURL;
    const cmsCustomApplySlug = cmsEntry.customApplySlug;


    if (!cmsURL && !apiURL) {
        throw new Error(`[getApplyHrefForBanking] Product type ${cmsEntry.type} with id ${cmsEntry.id} is missing key information: ${JSON.stringify(cmsEntry, null, 4)}`);
    }

    if (Config.ENABLE_CC_CUSTOM_APPLY_SLUG_MIGRATION || cmsCustomApplySlug) {
        if (cmsCustomApplySlug && apiURL) {
            return addSearchParams(apiURL, {
                [ AFFILIATE_QUERY_PARAM_KEYS.customApplySlug ]: cmsCustomApplySlug,
            }).toString();

            // crypto is a skeleton vertical that can't support custom apply slugs
        } else if (apiEntry.productType === Products.CRYPTO && cmsURL && apiURL) {
            const encodedURL = typeof btoa === 'function'
                ? btoa(cmsURL)
                : new Buffer.from(cmsURL).toString('base64');

            // crypto doesn't have API or conversion support yet, so we use the generic /go route for redirects
            return `${apiURL}/go/${encodedURL}`;
        } else if (apiURL) {
            return apiURL;
        } else {
            throw new Error(`[getApplyHrefForBanking] Product type “${cmsEntry.type}” with id “${cmsEntry.id}” is missing an apply URL: “${JSON.stringify(apiEntry, null, 4)}”`);
        }
        // TODO: Remove below and if check after ENABLE_CC_CUSTOM_APPLY_SLUG_MIGRATION migration is complete
    } else {
        /**
         * If a cmsURL is supplied, ensure it is encoded so that
         * when we append it as a query param, it is not malformed
         * due to it potentially containing its own query params.
         */
        if (cmsURL && apiURL) {
            const encodedURL = typeof btoa === 'function'
                ? btoa(cmsURL)
                : new Buffer.from(cmsURL).toString('base64');

            // crypto doesn't have API or conversion support yet, so we use the generic /go route for redirects
            return apiEntry.productType === Products.CRYPTO
                ? `${apiURL}/go/${encodedURL}`
                : addSearchParams(apiURL, {
                    goto_64: encodedURL,
                }).toString();
        } else if (apiURL) {
            return apiURL;
        } else {
            throw new Error(`[getApplyHrefForBanking] Product type ${cmsEntry.type} with id ${cmsEntry.id} is missing key information from response: ${JSON.stringify(apiURL, null, 4)}`);
        }
    }
}

export default getApplyHrefForBanking;
