import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Colours, Sizes, Paragraph } from '@ratehub/base-ui';

import VisualElement from './VisualElement';


const VARIANTS = {
    LIGHT: 'light',
    DARK: 'dark',
};

function HumbleBragItem({
    majorText,
    minorText,
    iconKey,
    variant = VARIANTS.DARK,
    ...otherProps
}) {
    return (
        <HumbleBragItem.ListItem {...otherProps}>
            <div className="grid">
                <VisualElement
                    className="icon"
                    iconKey={iconKey}
                    iconStroke={variant === VARIANTS.DARK ? Colours.BLACKBERRY : Colours.COCONUT}
                />

                <Paragraph
                    className="major-text"
                    variant={variant}
                    showDot={false}
                    size="large"
                >
                    {majorText}
                </Paragraph>

                <Paragraph
                    className="minor-text"
                    variant={variant}
                >
                    {minorText}
                </Paragraph>
            </div>
        </HumbleBragItem.ListItem>
    );
}

HumbleBragItem.propTypes = {
    majorText: PropTypes.string.isRequired,
    minorText: PropTypes.string.isRequired,
    iconKey: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(Object.values(VARIANTS)),
};

HumbleBragItem.ListItem = styled.li`
    margin: 0 ${Sizes.SPACING.ONE};
    padding-top: ${Sizes.SPACING.TWO};

    flex: 1 1 12rem;

    > .grid {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
        -ms-grid-rows: auto 1fr 1.75fr;
        grid-template-rows: auto 1fr 1.75fr;

        margin: 0;
        color: ${Colours.COCONUT};

        height: 100%;

        > .icon {
            -ms-grid-row: 1;
            grid-row: 1;

            display: block;
            height: ${Sizes.ICONS.XL};
            width: ${Sizes.ICONS.XL};

            margin: 0 auto ${Sizes.SPACING.ONE};
        }

        > .major-text {
            -ms-grid-row: 2;
            grid-row: 2;

            text-align: center;

            font-size: ${Sizes.FONTS.XL};
            line-height: 1.875rem;

            margin-top: 0;
            margin-bottom: ${Sizes.SPACING.TWO};
        }

        > .minor-text {
            -ms-grid-row: 3;
            grid-row: 3;

            text-align: center;

            line-height: 1.625rem;

            margin-top: 0;
            margin-bottom: 0;
        }
    }
`;

HumbleBragItem.blockKey = 'rh/humble-brag-item';

export default HumbleBragItem;
