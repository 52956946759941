import { noticeError, Config, AFFILIATE_QUERY_PARAM_KEYS, addSearchParams } from '@ratehub/base-ui';
import { createCardSynopsisFromAPIResponse, fetchCreditCards, fetchCreditCardsFeatured } from '@ratehub/cc-common';

import { Products } from '../definitions/Products';


async function enrichCreditCardProducts(slots, options = {}) {
    // Error most likely unneeded, but just in case script missed a v1 -> v2 migration
    if (!slots.every(product => product.requested)) {
        noticeError(
            new Error('[enrichCreditCardProducts] Product shape is incompatible'),
            { slots },
        );
        return;
    }

    const { hideCTAIfNotMonetized } = options;

    let response = [];
    if (hideCTAIfNotMonetized) {
        response = (await fetchCreditCards({
            ids: slots.map(({ requested }) => requested[0].id),
            ...options,
        })).creditCards;
    } else {
        response = (await fetchCreditCardsFeatured({
            products: slots.map(({ requested, fallback }) => ({
                requested: requested.map(product => ({
                    id: product.id,
                })),
                // BE doesn't fallback correctly if type is undefined/null
                fallback: fallback?.type
                    ? { type: fallback.type }
                    : {},
            })),
            ...options,
        }));
    }

    response = response.filter(apiResponse => !!apiResponse)
        .map(apiResponse => createCardSynopsisFromAPIResponse(apiResponse));

    slots.forEach((slot, index) => {
        let matchedCard;

        for (let i = 0; i < slot.requested.length; i++) {
            const creditCard = slot.requested[i];

            // search the API response for the CMS-requested creditCard.id
            matchedCard = response.find(
                cardSynopsis => creditCard.id === cardSynopsis.primaryId);

            // Cards are not always featured, so a matched card is not guaranteed
            if (matchedCard) {
                enrichCreditCard(creditCard, matchedCard, slot);
                // We only need to enrich the first product we come across.
                break;
            }
        }

        // If no card is found, and hideCTAIfNotMonetized is disabled use fallback
        if (!matchedCard) {
            if (!hideCTAIfNotMonetized) {
                const fallbackCard = response[index];
                if (fallbackCard) {
                    enrichCreditCard(slot.fallback, fallbackCard, slot);
                }
            // otherwise, disable the slot because we won’t replace it with a fallbackCard when hideCTAIfNotMonetized is true
            } else {
                // since we can't actually delete a slot due to the way slots are passed by reference
                // have to mutate slot type and productType to undefined so it won't show previously fetch product
                Object.assign(slot, { productType: undefined, type: undefined });
            }
        }
    });
}

function enrichCreditCard(cmsEntry, cardSynopsis, slot) {
    if (!cmsEntry || !cardSynopsis) {
        const emptyError = new RangeError(`[enrichCreditCardProducts:enrichCreditCard] ${!cmsEntry
            ? '• missing cmsEntry'
            : ''} ${!cardSynopsis
            ? '• missing cardSynopsis'
            : ''}
        `);
        noticeError(emptyError, {
            cmsEntry,
            cardSynopsis,
        });

        throw emptyError;
    }

    const cmsId = cmsEntry.id?.match(/^\d+$/) // cmsEntry if using fallback may not have an id
        ? parseInt(cmsEntry.id)
        : cmsEntry.id;

    // all enrich* functions are designed to mutate the passed-in object (to enrich them with additional information)
    // this means we MUST mutate the slot object, and cannot just return a new one. The passed-in object is also reused
    // for refetching, so cannot remove or wrongfully update any fields required for fetching.
    Object.assign(slot, {
        id: cmsId || cardSynopsis.id,       // cardSynopsis.id does not require conversion
        productType: Products.CREDIT_CARDS,
        title: cmsEntry.title || cardSynopsis.name,
        isSponsored: cmsEntry.isSponsored || cardSynopsis.isSponsored,      // does not have to prefer cmsEntry
        hasPromo: !!cardSynopsis.giftCardOffer,

        applyHref: getApplyHref(cmsEntry, cardSynopsis),
        // avoid assigning an empty applyText string
        applyText: cmsEntry.applyText?.trim().length
            ? cmsEntry.applyText
            : undefined,
        description: cmsEntry.description || cardSynopsis.description,

        imageSrc: cardSynopsis.cardImageURL,
        imageAlt: cardSynopsis.name,

        // creditCardSynopsis required to support credit card gift card modal
        creditCardSynopsis: cardSynopsis,

        // required by heap tracking
        detailsHref: cardSynopsis.detailsURL,
        isMonetized: cardSynopsis.isMonetized,
        providerSlug: cardSynopsis.bank,

        // avoid assigning an empty productTypeOverride string
        productTypeOverride: cmsEntry.productTypeOverride?.trim().length
            ? cmsEntry.productTypeOverride
            : undefined,
    });
}

function getApplyHref(cmsEntry, apiEntry) {
    const cmsURL = cmsEntry.applyHref;
    const apiURL = apiEntry.applyRedirectURL;
    const cmsCustomApplySlug = cmsEntry.customApplySlug;

    if (Config.ENABLE_CC_CUSTOM_APPLY_SLUG_MIGRATION || cmsCustomApplySlug) {
        if (cmsCustomApplySlug && apiURL) {
            return addSearchParams(apiURL, {
                [ AFFILIATE_QUERY_PARAM_KEYS.customApplySlug ]: cmsCustomApplySlug,
            }).toString();
        } else if (apiURL) {
            return apiURL;
        } else {
            throw new Error(`[enrichCreditCardProducts] Product type “${cmsEntry.type}” with id “${cmsEntry.id}” is missing an apply URL: “${JSON.stringify(apiEntry, null, 4)}”`);
        }
    // TODO: Remove below and if check after ENABLE_CC_CUSTOM_APPLY_SLUG_MIGRATION migration is complete
    } else {
        // URL already encoded so use it immediately
        if (cmsURL?.includes('goto_64')) {
            return cmsURL;
        }

        if (cmsURL && apiURL) {
            const encodedURL = typeof btoa === 'function'
                ? btoa(cmsURL)
                : new Buffer.from(cmsURL).toString('base64');

            return addSearchParams(apiURL, {
                goto_64: encodedURL,
            }).toString();
        } else if (apiURL) {
            return apiURL;
        } else {
            throw new Error(`[enrichCreditCardProducts] Product type “${cmsEntry.type}” with id “${cmsEntry.id}” is missing an apply URL: “${JSON.stringify(apiEntry, null, 4)}”`);
        }
    }
}


export default enrichCreditCardProducts;
