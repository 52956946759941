import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import classNames from 'classnames';

import RemoveDefaultButtonStyles from '../styles/RemoveDefaultButtonStyles';
import messageToString from '../functions/messageToString';
import Colours from '../definitions/Colours';
import Typography from '../definitions/Typography';
import Sizes from '../definitions/Sizes';
import Dot from './Dot';


const VARIANTS = {
    LIGHT: 'light',
    DARK: 'dark',
};
const SIZES = {
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
};

function TabListButton({
    id,
    variant = VARIANTS.DARK,
    size = SIZES.MEDIUM,
    label, // @TODO: Rename this to `title` to match props in other tab components
    subTitle,
    isSelected,
    onClick,
    ...otherProps
}) {
    const intl = useIntl();

    return (
        <Container
            id={id}
            type="button"
            isSelected={isSelected}
            aria-selected={isSelected}
            onClick={() => onClick?.()}
            variant={variant}
            size={size}
            role="tab"
            data-name="tabListButton-showTab"
            {...otherProps}
        >
            <Dot className="dot" />

            <div>
                <p className="rh-m-0">
                    {messageToString(label, intl)}
                </p>
                <If condition={subTitle}>
                    <p className={classNames('rh-m-0', {
                        'rh-text-l': size === SIZES.LARGE,
                        'rh-text-m': size === SIZES.MEDIUM,
                        'rh-text-s': size === SIZES.SMALL,
                    })}
                    >
                        {messageToString(subTitle, intl)}
                    </p>
                </If>
            </div>
        </Container>
    );
}

TabListButton.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
    isSelected: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
    variant: PropTypes.oneOf(Object.values(VARIANTS)),
    size: PropTypes.oneOf(Object.values(SIZES)),
};

const TRANSITION_DURATION = '300ms';

const Container = styled.button`
    ${RemoveDefaultButtonStyles};

    position: relative;

    /* Using em in this component instead of rem so the
        styles can scale based on which size we're on */
    margin-bottom: 0.75em;
    padding-left: ${props => props.isSelected ? '1.25em' : '0'};

    color: ${props => props.variant === 'light'
        ? Colours.COCONUT
        : Colours.BLACKBERRY};

    font-family: ${Typography.FONT_FAMILY_STACK};
    text-align: left;
    text-decoration: none;

    cursor: pointer;
    transition:
        padding-left ${TRANSITION_DURATION},
        color ${TRANSITION_DURATION};

    color: ${props => props.variant === 'light'
        ? Colours.COCONUT
        : Colours.BLACKBERRY};
    
    ${props => props.isSelected && `
        font-weight: ${Typography.WEIGHTS.MEDIUM};
    `}

    ${props => props.size === SIZES.LARGE && `
        font-size: ${Sizes.FONTS['3XL']};
        font-weight: ${Typography.WEIGHTS.MEDIUM};
        line-height: 36px;
        color: ${Colours.STONE_DARKEST};
    `}

    ${props => props.size === SIZES.MEDIUM && `
        font-size: ${Sizes.FONTS.XL};
        line-height: 30px;
    `}

    ${props => props.size === SIZES.SMALL && `
        font-size: ${Sizes.FONTS.M};
        line-height: 24px;
    `}

    &:hover,
    &:focus {
        color: ${Colours.BLUEBERRY};
    }

    .dot {
        position: absolute;
        top: 50%;
        transform: translateY(-50%) scale(0);
        left: 0;

        width: 0.6em;
        height: 0.6em;

        transform: scale(0);
        transition: transform ${TRANSITION_DURATION};

        ${props => props.isSelected && `
            transform: translateY(-50%) scale(1);
        `};
    }
`;

export default TabListButton;
