import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import classNames from 'classnames';

import {
    LayoutRow,
    Column,
    AccordionItem,
    HeroBannerWide,
    CallToActionBlock,
    CONTAINER_BLOCKS,
    withAnimations,
    createNestedAnimationsDefinition,
    SiteSettings,
    ExperimentSegment,
    PAGE_CONTENT_BLOCK_ATTRIBUTES,
    BillboardImageContainer,
    ImageBlock,
    ScenarioChooser,
    mapPCBtoLayoutRowAttributes,
} from '@ratehub/web-components';
import {
    usePageSettings,
    getBGColourClassName,
    getLayoutWidthClassName,
} from '@ratehub/base-ui';

import getFilteredComponentAttributes from '../functions/getFilteredComponentAttributes';
import ServerOnlyReplacementBlock from './ServerOnlyReplacementBlock';


// REQUIREMENT: Force disable left & right "content padding" from
// the innerBlocks of these blocks.
const BLOCKS_WITH_UNPADDED_INNERBLOCKS = [
    Column.blockKey,
    AccordionItem.blockKey,
    HeroBannerWide.blockKey,
    CallToActionBlock.blockKey,
];

const PCB_KEYS = Object.values(PAGE_CONTENT_BLOCK_ATTRIBUTES);

const DEFAULT_TO_WIDE = [
    BillboardImageContainer.blockKey,
    ImageBlock.blockKey,
    ScenarioChooser.blockKey,
];

function CMSComponentSelector({
    blockName,
    attrs = {},
    innerBlocks = [],
    blockMap = {},
    rowIndex,
    options = {
        includeContentPadding: true,
        as: 'section',
        parentBlockName: undefined,
        depth: 0,
        isTopLevelBlock: true,
    },
}) {
    const Component = getBlockComponent(blockName, blockMap);

    if (Component == null) {
        return null;
    }

    // Overwrite prop if needed - unless explicitly false
    if ('includeContentPadding' in attrs) {
        attrs.includeContentPadding = attrs.includeContentPadding === false
            ? false
            : options.includeContentPadding;
    }

    // Special case for immediate Column contents
    if (options.parentBlockName === Column.blockKey) {
        attrs.includeContentPadding = false;
    }

    // Set attribute value so we can jam in some styling (see ExperimentSegment css)
    if (blockName === ExperimentSegment.blockKey) {
        attrs.isTopLevelBlock = options.isTopLevelBlock;

        if (options.isTopLevelBlock) {
            const regex = /rh-layout-(default|full|pull-right)/gm;

            // Remove any existing layout classes and add the one from the experiment segment
            innerBlocks.forEach(block => {
                if (block.attrs) {
                    block.attrs.className = classNames(
                        block.attrs?.className?.replace(regex, '').trim(),
                        getLayoutWidthClassName(attrs.layoutWidth),
                    );
                }
            });
        }
    }

    const pageSettings = usePageSettings();

    // REQUIREMENT: Wrap block with LayoutRow if requested. Otherwise, do not
    // wrap block at all.
    const LayoutComponent = Component.requiresLayoutRow
        ? LayoutRow
        : undefined;

    const hasLayoutComponent = LayoutComponent !== undefined;

    const Container = hasLayoutComponent ? LayoutComponent : Fragment;

    // checks if component animation is there
    const hasAnimations = Component.animations && Component.animations.length != 0;

    // Add to our options
    const extendedOptions = {
        ...options,
        hasSidebar: pageSettings.hasSidebar,
        blockName: blockName,
    };

    // layoutAttrs will applied to a LayoutComponent, if present.
    // If no LayoutComponent is present, no attributes are needed.
    const layoutAttrs = hasLayoutComponent
        ? getLayoutAttrs(attrs, rowIndex, extendedOptions)
        : null;

    // CRITICAL: this needs to be wrapped with useMemo to avoid re-creating ContainerWithAnimations every render.
    // If it's re-create every render, it will re-mount (destroy previous, then mount new) all children every render!
    // This will cause havoc for things like Ads, who's indexing is reliant on not being re-mounted this way.
    // Ideally this would be useState (because useMemo is not a guaranteed) but use of useState causes a crash
    // "Error: Element type is invalid: expected a string (for built-in components) or a class/function (for composite components) but got: object."
    const ContainerWithAnimations = useMemo(
        () => withAnimations(Container, createNestedAnimationsDefinition(Component.animations)),
        [ Container, Component.animations ],
    );

    // Only pass props if the Component truly has animations.
    const animationProps = {};
    if (hasAnimations) {
        Component.animations.forEach(blockAnimation => {
            if (attrs[blockAnimation]) {
                animationProps[blockAnimation] = attrs[blockAnimation];
            }
        });
    }

    // Compute backgroundColour value, if any, for our peer bg element
    const backgroundColour = extendedOptions.isTopLevelBlock && getBackgroundColour(blockName, attrs);

    // Get all attributes with the exception of layout and animation keys
    // (those should be applied to a layout container, not the web component)
    const filteredComponentAttributes = getFilteredComponentAttributes(attrs, {
        // Filter PCB keys when either layout component is in use (we are
        // currently mapping PCB keys for use on LayoutRow so LayoutRow may
        // encounter attributes meant for either layout component.
        shouldFilterPCBKeys: LayoutComponent === LayoutRow,
        shouldFilterLayoutRowKeys: LayoutComponent === LayoutRow,
        shouldFilterAnimationKeys: hasAnimations,
    });

    // Get attributes which should only apply to our "top-level" components.
    const topLevelAttributes = !hasLayoutComponent && !isWrapperBlock(blockName)
        ? getTopLevelAttributes(filteredComponentAttributes, rowIndex, extendedOptions)
        : { className: filteredComponentAttributes.className };

    return (
        <If condition={Component}>
            <>
                <ContainerWithAnimations
                    {...animationProps}
                    {...omit(layoutAttrs, 'blockLocation')}
                >
                    <Component
                        {...filteredComponentAttributes}
                        {...topLevelAttributes}

                        className={classNames(
                            {
                                // forces web-components with narrow content to take up
                                // the full width available to it.
                                'rh-flex-grow-1': LayoutComponent === LayoutRow,

                                // forces web-components with overflowing content
                                // to render a scrollbar, but only if necessary.
                                'rh-min-width-0': LayoutComponent === LayoutRow,
                            },

                            topLevelAttributes?.className,
                        ) || undefined}
                    >
                        <If condition={innerBlocks.length}>
                            <For
                                each="child"
                                of={innerBlocks}
                                index="index"
                            >
                                <CMSComponentSelector
                                    key={`${child.blockName}-${index}`}
                                    blockMap={blockMap}
                                    options={{
                                        includeContentPadding: BLOCKS_WITH_UNPADDED_INNERBLOCKS.includes(blockName)
                                            || !(extendedOptions.isTopLevelBlock && blockName === ExperimentSegment.blockKey),
                                        as: 'div',
                                        parentBlockName: blockName,
                                        isTopLevelBlock: extendedOptions.isTopLevelBlock && isWrapperBlock(blockName),
                                    }}
                                    rowIndex={rowIndex}
                                    {...child}
                                />
                            </For>
                        </If>
                    </Component>
                </ContainerWithAnimations>

                {/* Some people don't like the effect when the siderail is fixed and we have this peer div */}
                <If condition={backgroundColour && !pageSettings.isSidebarFixed}>
                    <div
                        // Taking all attr.classNames here, but it's really only margin I think would be required.
                        className={classNames('rh-layout-bg-stretch', getBGColourClassName(backgroundColour), attrs.className)}
                        style={{ gridRow: rowIndex + 1 }}
                    />
                </If>
            </>
        </If>
    );
}

CMSComponentSelector.propTypes = {
    blockName: PropTypes.string,
    /* If no attrs are passed, it returns an empty array and not undefined/null/whatever */
    attrs: PropTypes.oneOfType([ PropTypes.object, PropTypes.array ]),
    innerBlocks: PropTypes.array,
    blockMap: PropTypes.object,
    rowIndex: PropTypes.number,
    options: PropTypes.shape({
        includeContentPadding: PropTypes.bool,
        as: PropTypes.string,
        parentBlockName: PropTypes.string,
        depth: PropTypes.number,
        isTopLevelBlock: PropTypes.bool,
    }),
};

// REQUIREMENT: wrapper blocks are not considered "top-level". The immediate child
// blocks ARE, but only at the very top of the hierarchy.
function isWrapperBlock(blockName) {
    const blocksToFilter = CONTAINER_BLOCKS.filter(block => block !== ExperimentSegment.blockKey);

    return blocksToFilter.includes(blockName);
}

function getBlockComponent(blockName, blockMap) {
    // SPECIAL CASE: it's a stand-in for a block which is only rendered on the server
    if (blockName === ServerOnlyReplacementBlock.blockKey) {
        return ServerOnlyReplacementBlock;
    }

    return blockMap?.[blockName]?.render;
}

// Extract the layout-related attributes from the block's attrs. Only called
// when we know a layout component is in use.
function getLayoutAttrs(attrs, rowIndex, options) {
    // TEMPORARY: Currently, all layout attributes are PageContentBlock
    // attributes as that is what we've historically used. We will likely
    // script those all to be true LayoutRow attributes at some point. Once
    // scripted, we can remove the mapping here and just directly return
    // LayoutRow attributes only.
    const pcbAttrs = getPageContentBlockAttrs(attrs, rowIndex, options);

    return mapPCBtoLayoutRowAttributes(pcbAttrs);
}

function getPageContentBlockAttrs(attrs, rowIndex, options) {
    const props = {
        as: options.as,
        includeContentPadding: options.includeContentPadding,

        // Note that this may override includeContentPadding
        ...getTopLevelAttributes(attrs, rowIndex, options),
    };

    // Makes sure the PCB gets the attributes it needs from our block attributes
    PCB_KEYS.forEach(key => {
        if (key in attrs) {
            props[key] = attrs[key];
        }
    });

    return props;
}

// Applies attributes to “top-level” (non-container) blocks
// Attributes are used by TableOfContents and Layout.
function getTopLevelAttributes(attrs, rowIndex, options) {
    return options.isTopLevelBlock
        ? {
            ...({ [SiteSettings.TOP_LEVEL_BLOCK_DATA_ATTRIBUTE_NAME]: true }),

            // Remove content padding from all top level blocks as this is now supplied by the grid
            includeContentPadding: false,

            // Adding an inline style because the alternative is generating a ton of utility classes or using a prop in styled components
            style: {
                gridRow: rowIndex + 1,
            },

            className: classNames(
                // Want to force certain components to always be full
                options.blockName === HeroBannerWide.blockKey || (!attrs.layoutWidth && DEFAULT_TO_WIDE.includes(options.blockName))
                    ? 'rh-layout-full'
                    : getLayoutWidthClassName(attrs.layoutWidth),
                {
                    'rh-max-width-override': options.hasSidebar,
                },
                attrs.className,
            ),
        }
        : {
            className: attrs.className,
        };
}

// Return a background colour if desired
function getBackgroundColour(blockName, attrs) {
    if (!isWrapperBlock(blockName)) {
        return attrs.backgroundColour;
    }

    return '';
}

export default CMSComponentSelector;
