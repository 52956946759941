import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sizes } from '@ratehub/base-ui';

import HeadingPropTypes from '../definitions/HeadingPropTypes';
import HeadingBlock from './HeadingBlock';


const VARIANTS = {
    LIGHT: 'light',
    DARK: 'dark',
};

function HumbleBragContainer({
    heading,
    variant = VARIANTS.DARK,
    children,
    ...otherProps
}) {
    return (
        <HumbleBragContainer.Container {...otherProps}>
            <HeadingBlock
                {...heading}
                variant={variant}
            />

            <ul>
                {children}
            </ul>
        </HumbleBragContainer.Container>
    );
}

HumbleBragContainer.propTypes = {
    heading: HeadingPropTypes,
    variant: PropTypes.oneOf(Object.values(VARIANTS)),
    children: PropTypes.array.isRequired,
};

HumbleBragContainer.Container = styled.div`
    > ul {
        display: flex;
        flex-wrap: wrap;

        list-style: none;
        margin: 0 -${Sizes.SPACING.ONE};
        padding: 0;
    }
`;

HumbleBragContainer.blockKey = 'rh/humble-brag';
HumbleBragContainer.hasHeading = true;
HumbleBragContainer.requiresLayoutRow = true;

export default HumbleBragContainer;
