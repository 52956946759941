import React from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component';
import styled, { css } from 'styled-components';
import classNames from 'classnames';

import { IconChooser, Colours } from '@ratehub/base-ui';

import getValueWithoutUnit from '../functions/getValueWithoutUnit';
import ImageCollectionPropType from '../definitions/ImageCollectionPropType';
import AdBigbox from './AdBigbox';


const WEBP_MIME = 'image/webp';
const WEBP_EXTENSION = '.webp';

function VisualElement(props) {
    const {
        isAd = false,

        iconKey,
        iconComponent,
        iconStroke = Colours.BLACKBERRY,
        iconStrokeWidth = '2',
        iconOutlineWidth = '2',

        imageUrl,
        alt = '',
        imageCollection,
        isLazy = false,
        restrictImageWidth = false,

        borderRadius,
        borderWidth,
        borderColour,

        customWidth,
        customHeight,

        dataName,
        sizingClassName,
        className,

        ...otherProps
    } = props;

    const Icon = iconComponent;
    const Image = isLazy ? StyledLazyLoadImage : StyledImg;
    const PictureContainer = isLazy ? LazyLoadComponent : React.Fragment;

    return (
        <Choose>
            <When condition={isAd}>
                <AdBigbox
                    alignment={AdBigbox.ALIGNMENTS.LEFT}
                    {...otherProps}
                />
            </When>

            {/* NOTE: We currently do not support `fill` as an icon prop in this
            component, despite most (if not all) icons accepting it. We would
            like to add this but need to make sure our icons are using it
            properly first. Many icons apply fill poorly, which results in
            broken-looking icons.
            See https://ratehub.atlassian.net/browse/FIT-1599 */}
            <When condition={iconKey && iconKey !== 'none'}>
                <IconChooser
                    {...otherProps}

                    size="FILL"
                    iconKey={iconKey}
                    stroke={iconStroke}
                    strokeWidth={iconStrokeWidth}
                    outlineWidth={iconOutlineWidth}
                    className={className}
                />
            </When>
            <When condition={iconComponent}>
                <Icon
                    {...otherProps}

                    stroke={iconStroke}
                    strokeWidth={iconStrokeWidth}
                    outlineWidth={iconOutlineWidth}

                    width={customWidth && getValueWithoutUnit(customWidth)}
                    height={customHeight && getValueWithoutUnit(customHeight)}

                    className={classNames(className, sizingClassName)}
                />
            </When>
            <When condition={imageCollection}>
                {/* With a <picture> we have to lazy load the entire component. This is because <picture> requires an
                    <img> tag to use as a fallback and if we used LazyLoadImage for the <img> there will be no <img> for the <picture> */}
                <PictureContainer 
                    placeholder={(
                        <div 
                            className={classNames(className, sizingClassName, restrictImageWidth ? 'rh-max-width-100p' : undefined)} 
                            // Only apply the width/height if we don't have a sizing class name
                            //  otherwise these inline styles will override the styles from the class
                            style={sizingClassName
                                ? {}
                                : { 
                                    width: customWidth ?? imageCollection.width, 
                                    height: customHeight ?? imageCollection.height, 
                                }}
                        />
                    )}
                >
                    <picture>
                        <If condition={imageCollection.mime === WEBP_MIME}>
                            <For
                                each="image"
                                of={imageCollection.sizes}
                            >
                                <source
                                    key={image.url + image.breakpoint}
                                    srcSet={image.url.substring(0, image.url.length - WEBP_EXTENSION.length)}
                                    media={image.breakpoint}
                                    /**
                                     * Commenting this out here because the behaviour is different for Chrome/Firefox
                                     * Chrome uses the width/height in the source tag, but Firefox only uses the ones on the image tag
                                     */
                                    // width={image.width}
                                    // height={image.height}
                                    data-name={dataName}
                                />
                            </For>
                        </If>

                        <For
                            each="image"
                            of={imageCollection.sizes}
                        >
                            <source
                                key={image.url + image.breakpoint}
                                srcSet={image.url}
                                media={image.breakpoint}
                                type={imageCollection.mime}
                                // See comment above
                                // width={image.width}
                                // height={image.height}
                                data-name={dataName}
                            />
                        </For>

                        {/* We have to use a raw img here instead of lazy loading the <img> because <picture> expects an <img> as a fallback */}
                        <StyledImg
                            // Spreading other props first because width/height can techincally be overridden if passed as props
                            {...otherProps}

                            src={imageCollection.fallback}
                            alt={alt || imageCollection.alt || imageCollection.title || ''}
                            width={customWidth ? getValueWithoutUnit(customWidth) : imageCollection.width}
                            height={customHeight ? getValueWithoutUnit(customHeight) : imageCollection.height}
                            $restrictImageWidth={restrictImageWidth}
                            $borderRadius={borderRadius}
                            $borderWidth={borderWidth}
                            $borderColour={borderColour}
                            data-name={dataName}

                            // display: block is required here for the <picture> to be the true size of the image
                            //  otherwise the <picture> will add ~15px of additional height
                            className={classNames(className, sizingClassName, 'rh-display-block')}
                        />
                    </picture>                    
                </PictureContainer>
            </When>
            {/* imageCollection should take priority */}
            <When condition={imageUrl && !imageCollection}>
                <Image
                    {...otherProps}

                    src={imageUrl}
                    alt={alt}

                    
                    width={customWidth ? getValueWithoutUnit(customWidth) : undefined}
                    height={customHeight ? getValueWithoutUnit(customHeight) : undefined}
                    $restrictImageWidth={restrictImageWidth}

                    $borderRadius={borderRadius}
                    $borderWidth={borderWidth}
                    $borderColour={borderColour}
                    data-name={dataName}

                    className={className}
                />
            </When>
        </Choose>
    );
}

VisualElement.propTypes = {
    isAd: PropTypes.bool,
    targeting: PropTypes.object,

    iconKey: PropTypes.string,
    iconComponent: PropTypes.func,
    iconStroke: PropTypes.oneOf(
        Object.values(Colours),
    ),
    iconStrokeWidth: PropTypes.string,
    iconOutlineWidth: PropTypes.string,

    imageUrl: PropTypes.string,
    alt: PropTypes.string,
    imageCollection: ImageCollectionPropType,
    isLazy: PropTypes.bool,
    restrictImageWidth: PropTypes.bool,

    borderRadius: PropTypes.string,
    borderWidth: PropTypes.string,
    borderColour: PropTypes.oneOf(Object.values(Colours)),

    // These are used only by CMS blocks, the expectation is
    //  that these are px value in strings like '250px'
    customWidth: PropTypes.string,
    customHeight: PropTypes.string,

    dataName: PropTypes.string,

    // Separating sizingClassName from className because our placeholder values for
    //  lazy loaded images need to have the same size as the image itself. Sometimes this is 
    //  done via CSS because we want to use relative units like rem or % and so a className
    //  is easier to pass than having to use px or potentially JS to transform the relative units to px.
    sizingClassName: PropTypes.string,
    className: PropTypes.string,
};

const IMAGE_STYLES = css`
    border-radius: ${props => props.$borderRadius};
    border: ${props => computeBorderStyle(props.$borderWidth, props.$borderColour)};

    ${props => props.$restrictImageWidth && `
        max-width: 100%;
        height: auto;
    `}
`;

const StyledLazyLoadImage = styled(LazyLoadImage)`
    ${IMAGE_STYLES};
`;

const StyledImg = styled.img`
    ${IMAGE_STYLES};
`;

function computeBorderStyle(size, colour) {
    return size && colour
        ? `${size} solid ${colour}`
        : 'none';
}

export default VisualElement;
