import React from 'react';
import PropTypes from 'prop-types';


/**
 * Gutenberg editor only component
 * UI is defined in @ratehub/wordpress-blocks
 * We just need the blockKey to use as a unique ID
*/
function BlueprintInnerBlocks({ children }) {
    return (
        <>
            {children}
        </>
    );
}

BlueprintInnerBlocks.propTypes = {
    children: PropTypes.node,
};

BlueprintInnerBlocks.blockKey = 'rh/blueprint-inner-blocks';

export default BlueprintInnerBlocks;
