/* eslint-disable react/no-danger */
import React from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';

import {
    Config,
    usePageSettings,
    BRANDS,
    Languages,
    Locales,
} from '@ratehub/base-ui';

import ORGANIZATION_SCHEMA from '../definitions/OrganizationSchema.json';
import RH_INS_ORGANIZATION_SCHEMA from '../definitions/RhInsOrganizationSchema.json';


const ORG_SCHEMA_MAP = {
    [BRANDS.RATEHUB]: ORGANIZATION_SCHEMA,
    [BRANDS.RH_INSURANCE]: RH_INS_ORGANIZATION_SCHEMA,
};

const DEFAULT_PAGE_TITLE = Config.SITE_DEFAULT_PAGE_TITLE;

function HeadTag({ metaTitle, metaDescription }) {
    const pageSettings = usePageSettings();

    const title = metaTitle || pageSettings?.seoMeta?.title || DEFAULT_PAGE_TITLE;
    const description = metaDescription || pageSettings?.seoMeta?.metadesc || '';

    // Although userScalable is set to 'no' for iPhone/iPad, this does not work for iOS 10 and above
    // i.e. users will still be able to zoom in manually when needed
    // https://weblog.west-wind.com/posts/2023/Apr/17/Preventing-iOS-Safari-Textbox-Zooming
    // at first sight this piece of code seems useless, but it seems to "fix" the unwanted auto zoom-in when focused on input fields on iPhones
    // and allows manual zoom for all other devices. `navigator.userAgent` is a non-ideal way of checking for device
    // type, but this is currently the best supported method for looking for iOS devices specifically.
    const userScalable = typeof window !== 'undefined' && (navigator.userAgent.indexOf('iPhone') > -1 || navigator.userAgent.indexOf('iPad') > -1)
        ? 'no'
        : 'yes';

    // use the page's hero image as the featured image for social media link previews, or fall back to a default
    const featuredImageURL = pageSettings.heroImageURL || `${Config.RATEHUB_BASE_URL}/static/FB-opengraph-1200x630.jpg`;

    return (
        <Head>
            <meta
                name="viewport"
                content={`width=device-width, initial-scale=1, user-scalable=${userScalable}`}
            />

            <meta
                name="description"
                content={description}
            />

            <If condition={pageSettings.noIndex}>
                <meta
                    name="robots"
                    content="noindex"
                />
            </If>

            <If condition={pageSettings.pageTranslations}>
                <link
                    rel="alternate"
                    hrefLang="x-default"
                    href={pageSettings.pageUrl}
                />

                <For
                    each="link"
                    of={Object.entries(pageSettings.pageTranslations)}
                >
                    <link
                        rel="alternate"
                        href={`${Config.RATEHUB_BASE_URL}${link[1].url}`}
                        hrefLang={link[0] === Languages.FRENCH ? Locales.FRENCH : Locales.ENGLISH}
                    />
                </For>
            </If>

            <title>
                {title}
            </title>

            <link
                rel="canonical"
                href={pageSettings.pageUrl}
            />

            {/* OpenGraph meta data */}
            <meta
                property="og:url"
                content={pageSettings.pageUrl}
            />
            <meta
                property="og:type"
                content="website"
            />
            <meta
                property="og:site_name"
                content={DEFAULT_PAGE_TITLE}
            />
            <meta
                property="og:image"
                content={featuredImageURL}
            />
            <meta
                property="og:image:width"
                content="1200"
            />
            <meta
                property="og:image:height"
                content="630"
            />
            <meta
                property="og:title"
                content={title}
            />
            <meta
                property="og:description"
                content={description}
            />

            <meta
                name="twitter:card"
                content="summary_large_image"
            />
            <meta
                name="twitter:description"
                content={description}
            />
            <meta
                name="twitter:site"
                content="@ratehub"
            />
            <meta
                name="twitter:title"
                content={title}
            />
            <meta
                name="twitter:image"
                content={featuredImageURL}
            />

            {/* Organisation schema */}
            <If condition={pageSettings.includeOrganizationSchema}>
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{
                        __html: JSON.stringify(getOrganisationSchema(), null, 4),
                    }}
                />
            </If>

            {/* Article schema */}
            <If condition={pageSettings.includeArticleSchema}>
                <script
                    type="application/ld+json"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                        __html: JSON.stringify(getArticleSchema(pageSettings), null, 4),
                    }}
                />
            </If>
        </Head>
    );
}

HeadTag.propTypes = {
    metaTitle: PropTypes.string,
    metaDescription: PropTypes.string,
};

// Computes the org schema to use if SITE_BRANDING has a matching value, otherwise returns the RH default
function getOrganisationSchema() {
    return ORG_SCHEMA_MAP[Config.SITE_BRANDING] || ORGANIZATION_SCHEMA;
}

// Builds the article schema to use to display author/article information for SEO
function getArticleSchema({ author, pageUrl, seoMeta, heroImageURL, publishedDate, modifiedDate }) {
    return {
        '@context': 'https://schema.org',
        '@type': 'Article',
        mainEntityOfPage: {
            '@type': 'WebPage',
            '@id': pageUrl,
        },
        headline: seoMeta?.title,
        description: seoMeta?.metadesc,
        image: heroImageURL,
        author: {
            '@type': 'Person',
            name: author.name,
        },
        publisher: {
            '@id': 'https://www.ratehub.ca/schema/#ratehub',
        },
        datePublished: publishedDate,
        dateModified: modifiedDate,
    };
}

export default HeadTag;
