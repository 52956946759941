import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Sizes, Paragraph, AnchorWithIcon } from '@ratehub/base-ui';


function ArticleShowcaseItem({
    title,
    logo,
    logoAlt,
    snippet,
    href,
    ctaText,
    ...otherProps
}) {
    return (
        <ArticleShowcaseItem.Grid {...otherProps}>
            <If condition={logo}>
                <ArticleShowcaseItem.ImageContainer>
                    <ArticleShowcaseItem.Image
                        src={logo}
                        alt={logoAlt}
                    />
                </ArticleShowcaseItem.ImageContainer>
            </If>

            <ArticleShowcaseItem.Title
                className={classNames('rh-title-m', 'weight-regular', 'rh-m-0')}
                dangerouslySetInnerHTML={{
                    __html: title,
                }}
                hasGridGapOffset={!logo}
            />

            <ArticleShowcaseItem.Snippet>
                {snippet}
            </ArticleShowcaseItem.Snippet>

            <ArticleShowcaseItem.AnchorWithIcon
                href={href}
                data-name="articleShowCaseItem-cta"
                message={ctaText}
            />
        </ArticleShowcaseItem.Grid>
    );
}


ArticleShowcaseItem.propTypes = {
    title: PropTypes.string.isRequired,
    logo: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
    ]),
    logoAlt: PropTypes.string,
    snippet: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    ctaText: PropTypes.string.isRequired,
};

const GRID_GAP = Sizes.SPACING.TWO;

ArticleShowcaseItem.Grid = styled.li`
    display: grid;

    grid-template-rows: min-content max-content auto max-content;
    grid-row-gap: ${GRID_GAP};

    list-style: none;

    margin-left: 0;
    margin-bottom: ${Sizes.SPACING.TWO};

    @media (min-width: 1023px) {
        margin-bottom: 0;
    }
`;

ArticleShowcaseItem.ImageContainer = styled.div`
    grid-row: 1;

    display: flex;
    align-items: center;
    height: 70px;
    max-width: 240px;
`;

ArticleShowcaseItem.Image = styled.img`
    display: block;

    width: auto;
    height: auto;
    max-height: 100%;
    max-width: 100%;
`;

ArticleShowcaseItem.Title = styled.h3`
    /* Weird styled components behaviour - If you don't use && it falls back to stylesheet insertion order
    https://github.com/styled-components/styled-components/issues/1816 */
    && {
        -ms-grid-row: 3;
        grid-row: 2;

        margin-top: ${props => props.hasGridGapOffset ? `-${GRID_GAP}` : 0};
    }
`;


ArticleShowcaseItem.Snippet = styled(Paragraph)`
    /* Weird styled components behaviour - If you don't use && it falls back to stylesheet insertion order
    https://github.com/styled-components/styled-components/issues/1816 */
    && {
        -ms-grid-row: 5;
        grid-row: 3;

        margin: 0;
    }
`;

ArticleShowcaseItem.AnchorWithIcon = styled(AnchorWithIcon)`
    -ms-grid-row: 7;
    grid-row: 4;
`;


export default ArticleShowcaseItem;
