import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Sizes, PageBehaviourButton, IconPlus, IconMinus } from '@ratehub/base-ui';

import PAGE_TEMPLATES from '../definitions/PageTemplates';
import HeadingPropTypes from '../definitions/HeadingPropTypes';
import DateCategorizedTable from './DateCategorizedTable';
import HeadingBlock from './HeadingBlock';


const INITIAL_COUNT_TO_DISPLAY = 5;


function ArticleTable({
    heading,
    showMoreText,
    showLessText,
    articles = [],
    showLinkArrow = false,
    className,
}) {
    const [ shouldShowAllArticles, setShouldShowAllArticles ] = useState(false);

    // Need to clone articles so we're not mutating the props
    const filteredArticles = sortAllArticles(articles)
        .slice(0, shouldShowAllArticles ? articles.length : INITIAL_COUNT_TO_DISPLAY);

    return (
        <div className={className}>
            <HeadingBlock
                {...heading}
            />

            <DateCategorizedTable
                items={buildDateCategorizedTableItemShape(filteredArticles)}
                showLinkArrow={showLinkArrow}
            />

            <If condition={articles.length > INITIAL_COUNT_TO_DISPLAY}>
                <ArticleTable.PageBehaviourButton
                    onClick={() => setShouldShowAllArticles(!shouldShowAllArticles)}
                    iconComponent={shouldShowAllArticles ? IconMinus : IconPlus}
                    data-name="articleTable-showMoreLess"
                >
                    {shouldShowAllArticles
                        ? showLessText
                        : showMoreText}
                </ArticleTable.PageBehaviourButton>
            </If>
        </div>
    );
}

function buildDateCategorizedTableItemShape(articles) {
    return articles.map(a => {
        return {
            date: a.date,
            title: a.description || a.logoAlt,
            bodyText: a.snippet,
            href: a.href,
        };
    });
}

function sortAllArticles(articles) {
    return articles.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);

        return dateA > dateB ? -1 : 1;
    });
}

ArticleTable.propTypes = {
    heading: HeadingPropTypes,
    showMoreText: PropTypes.string.isRequired,
    showLessText: PropTypes.string.isRequired,
    articles: PropTypes.arrayOf(
        PropTypes.object,
    ),
    showLinkArrow: PropTypes.bool,
    className: PropTypes.string,
};

ArticleTable.PageBehaviourButton = styled(PageBehaviourButton)`
    margin: ${Sizes.SPACING.FOUR} auto 0;

    text-transform: lowercase;
`;

ArticleTable.blockKey = 'rh/article-table';
ArticleTable.hasHeading = true;
ArticleTable.requiresLayoutRow = true;
ArticleTable.supportedPageTypes = [
    PAGE_TEMPLATES.corporate,
];

export default ArticleTable;
