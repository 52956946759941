import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sizes, PrimaryAnchor } from '@ratehub/base-ui';

import HeadingPropTypes from '../definitions/HeadingPropTypes';
import TeamMemberPropType from '../definitions/TeamMemberPropType';
import TeamMembersList from './TeamMembersList';
import HeadingBlock from './HeadingBlock';


function TeamMemberShowcase({ heading, people, ctaHref, ctaText, hoverText, className }) {
    return (
        <div className={className}>
            <HeadingBlock
                {...heading}
            />

            <TeamMembersList
                people={people}
                hasTopMargin={!!heading?.title}
                hoverText={hoverText}
            />

            <If condition={ctaHref}>
                <TeamMemberShowcase.PrimaryButtonAnchor
                    href={ctaHref}
                >
                    {ctaText}
                </TeamMemberShowcase.PrimaryButtonAnchor>
            </If>
        </div>
    );
}

TeamMemberShowcase.propTypes = {
    heading: HeadingPropTypes,
    ctaText: PropTypes.string,
    ctaHref: PropTypes.string,
    people: PropTypes.arrayOf(TeamMemberPropType).isRequired,
    hoverText: PropTypes.string,
    className: PropTypes.string,
};

TeamMemberShowcase.PrimaryButtonAnchor = styled(PrimaryAnchor)`
    margin: ${Sizes.SPACING.FOUR} auto 0;
    max-width: 20rem;
`;

TeamMemberShowcase.blockKey = 'rh/team-member-showcase';
TeamMemberShowcase.hasHeading = true;
TeamMemberShowcase.requiresLayoutRow = true;

export default TeamMemberShowcase;
