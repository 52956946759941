import { fetchJSON, Config } from '@ratehub/base-ui';

import getLoanShape from './getLoanShape';


const API_ENDPOINT = `${Config.LOANS_API_URL}/loan-queue`; 

/**
 * Fetches loans based on the provided parameters.
 *
 * @param {Object} params - The parameters for fetching loans.
 * @param {string} params.province - The province code.
 * @param {string} params.language - The language code.
 * @param {Array<Object>} params.products - The array of product objects.
 * @param {Array<Object>} params.products[].requested - The array of requested products.
 * @param {string} params.products[].requested[].id - The ID of a requested product.
 * @param {Object} params.products[].fallback - The fallback product.
 * @param {string} [params.products[].fallback.type] - The type of the fallback product.
 * @returns {Promise<Object>} The response object containing the fetched loans.
 */
async function fetchLoans({ province, language, products }) {
    const params = {
        province,
        language,
        placements: products,
    };

    const response = await fetchJSON({
        url: API_ENDPOINT,
        options: {
            method:  'POST',
            headers: { 'Content-Type': 'application/json' },
            body:    JSON.stringify(params),
        },
    }, 'fetchLoans');

    return {
        data: {
            products: response.data?.loans?.map(getLoanShape) || [],
        },
    };
}

export default fetchLoans;
