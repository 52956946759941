import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import { useIntl, defineMessages } from 'react-intl';

import {
    AnchorAnimation,
    MessagePropType,
    ButtonStateColours,

    getTermsURL,
    getPrivacyPolicyURL,
    getContactRatehubURL,
    subscribeToNewsletter,

    usePageSettings,
} from '@ratehub/base-ui';

import InlineForm from './InlineForm';


const CONTAINER_MAX_WIDTH = '800px';

function NewsletterSignUpInlineForm({
    inputLabel,
    dataName = 'newsletter signup inline form',

    ctaVariant = 'blueberry-dark',

    onSignUpComplete,

    disclaimer = MESSAGES.DISCLAIMER,
    disclaimerVariant = 'dark',

    asyncButtonMessages = {
        ready: MESSAGES.BUTTON_READY,
        'in-progress': MESSAGES.BUTTON_IN_PROGRESS,
        complete: MESSAGES.BUTTON_COMPLETE,
    },
    asyncButtonComponent = 'primary',

    inputType = 'email', /* Temporary prop, only here to allow working around a WP issue, see block's Edit component */
    inputErrorMessage,
    inputPlaceholder,

    isCompact = false,
    className,

    ...otherProps
}) {
    const intl = useIntl();
    const ctx = usePageSettings();

    async function handleSignUpClick(formValue) {
        const response = await subscribeToNewsletter({
            businessUnit: ctx.businessUnit,
            email: formValue,
            pageType: ctx.pageType,
            tags: ctx?.tags,
            categories: ctx?.categories,
        });

        if (typeof onSignUpComplete === 'function') {
            // Notify caller that signup has finished, regardless of success/failure.
            // Include the status in case they want to do something with that
            // information (show/hide message to user?)
            onSignUpComplete(response.status);
        }

        if (!response) {
            // eslint-disable-next-line no-console
            console.error('Failed to submit newsletter signup.', response);
            return false;
        }
    }

    if (typeof disclaimer === 'object' || disclaimer === '') {
        const termsLink = getTermsURL(intl.locale);
        const privacyLink = getPrivacyPolicyURL(intl.locale);
        const contactLink = getContactRatehubURL(intl.locale);

        // If disclaimer is falsy, use the default disclaimer message object
        const messageId = disclaimer || MESSAGES.DISCLAIMER;

        disclaimer = intl.formatMessage(messageId, {
            terms: msg => <a href={termsLink}>{msg}</a>, // eslint-disable-line
            privacy: msg => <a href={privacyLink}>{msg}</a>, // eslint-disable-line
            contact: msg => <a href={contactLink}>{msg}</a>, // eslint-disable-line
        });
    }

    // The default disclaimer is an intl message (typeof object). Content authors can also supply
    // and override from the CMS (a string, which we need to pass to dangerouslySetInnerHTML in case they included links).
    // These are the shared props for both versions.
    const disclaimerProps = {
        className: classNames(
            'disclaimer',
            'rh-mx-auto',
            'rh-text-align-center',
            'rh-display-block',
            {
                'rh-text-xs': isCompact,
                'rh-text-s': !isCompact,
                'rh-mt-1_5': isCompact,
                'rh-mt-1': !isCompact,
                'rh-fg-blackberry' : disclaimerVariant === 'dark',
                'rh-fg-coconut' : disclaimerVariant === 'light',
            }),
    };

    return (
        <Container
            className={classNames(
                'rh-flex-grow-1 rh-display-flex rh-flex-wrap rh-justify-content-center',
                className,
            )}
            variant={disclaimerVariant}
        >
            <InlineForm
                inputLabel={inputLabel}
                inputType={inputType}
                inputName="newsletter-signup-inline-form-email"
                inputErrorMessage={inputErrorMessage}
                inputPlaceholder={inputPlaceholder}
                ctaVariant={ctaVariant}
                justifyContent="center"
                asyncButtonMessages={{
                    ready: asyncButtonMessages.ready,
                    ['in-progress']: asyncButtonMessages['in-progress'],
                    complete: asyncButtonMessages.complete,
                }}
                asyncButtonComponent={asyncButtonComponent}
                onClick={handleSignUpClick}
                dataName={dataName}
                isCompact={isCompact}
                {...otherProps}
            />

            <Choose>
                {/* disclaimer is an intl message */}
                <When condition={typeof disclaimer === 'object'}>
                    <span
                        {...disclaimerProps}
                    >
                        {disclaimer}
                    </span>
                </When>

                {/* disclaimer is a string coming from the CMS, use dangerouslySetInnerHTML */}
                <Otherwise>
                    <span
                        {...disclaimerProps}
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: disclaimer }}
                    />
                </Otherwise>
            </Choose>
        </Container>
    );
}

const MESSAGES = defineMessages({
    DISCLAIMER: {
        id: 'web-components.NewsletterSignUpInlineForm.disclaimer',
        defaultMessage: 'By submitting your email address, you acknowledge and agree to Ratehub.ca’s <terms>Terms of Use</terms> and <privacy>Privacy Policy</privacy>. <contact>Contact us</contact> for more information. You can unsubscribe at any time.',
    },
    BUTTON_READY: {
        id: 'NewsletterSignUpInlineForm.signUpButttonReady',
        defaultMessage: 'Sign up',
    },
    BUTTON_IN_PROGRESS: {
        id: 'NewsletterSignUpInlineForm.signUpButttonInProgress',
        defaultMessage: 'Signing up...',
    },
    BUTTON_COMPLETE: {
        id: 'NewsletterSignUpInlineForm.signUpButttonComplete',
        defaultMessage: 'Thanks for signing up!',
    },
});

NewsletterSignUpInlineForm.propTypes = {
    inputLabel: MessagePropType.isRequired,
    dataName: PropTypes.string,
    inputErrorMessage: MessagePropType,
    inputPlaceholder: PropTypes.string,

    ctaVariant: PropTypes.oneOf(Object.keys(ButtonStateColours.PRIMARY)),

    onSignUpComplete: PropTypes.func, // a callback to perform actions AFTER a user has tried to signup

    disclaimer: MessagePropType,
    disclaimerVariant: PropTypes.oneOf([ 'dark', 'light' ]),

    asyncButtonMessages: PropTypes.shape({
        ready: MessagePropType.isRequired,
        'in-progress': MessagePropType.isRequired,
        complete: MessagePropType.isRequired,
    }),
    asyncButtonComponent: PropTypes.oneOf([ 'primary', 'alternate' ]),

    inputType: PropTypes.string,

    isCompact: PropTypes.bool,
    className: PropTypes.string,
};

const Container = styled.div`
    > .disclaimer {
        max-width: ${CONTAINER_MAX_WIDTH};

        > a {
            ${AnchorAnimation};
        }
    }
`;

NewsletterSignUpInlineForm.blockKey = 'rh/newsletter-sign-up-inline-form';
NewsletterSignUpInlineForm.requiresLayoutRow = true;

export default NewsletterSignUpInlineForm;
