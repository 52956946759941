import getApplyHrefForBanking from './getApplyHrefForBanking';


/**
 * Enrich shared deposit fields for deposits product queues by assigning values from cmsEntry
 * or apiEntry to slot as appropriate to allow same product array format as other verticals
 * @param {object} cmsEntry
 * @param {object} apiEntry
 * @param {object} slot
 */
function enrichDepositsProduct(cmsEntry, apiEntry, slot) {
    if (!cmsEntry || !apiEntry) {
        throw new Error(
            `[enrichDepositsProduct] Both enrichment entries for deposits product expected. cmsEntry: ${JSON.stringify(cmsEntry, null, 4)}; apiEntry: ${JSON.stringify(apiEntry, null, 4)}`,
        );
    }

    Object.assign(slot, {
        id: apiEntry.id,
        title: cmsEntry.titleOverride || apiEntry.provider?.name,
        isSponsored: cmsEntry.isSponsored || apiEntry.isSponsored,
        hasPromo: apiEntry.hasPromo,
        applyHref: getApplyHrefForBanking(cmsEntry, apiEntry),
        applyText: cmsEntry.applyText,
        description: cmsEntry.description || apiEntry.badgeDescription,

        // provider information sourced from apiEntry
        providerName: apiEntry.provider?.name,
        imageSrc: apiEntry.provider?.logo,
        imageAlt: apiEntry.provider?.name || '',
        providerSlug: apiEntry.provider?.slug || '',

        detailsHref: apiEntry.detailsURL || '',
        // isMonetized should always be passed as true from BE
        isMonetized: apiEntry.isMonetized,
    });
}


export default enrichDepositsProduct;
