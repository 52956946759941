import React from 'react';
import PropTypes from 'prop-types';

import { TableRawHTML, TABLE_STYLES } from '@ratehub/base-ui';


function GeneratedTable({
    title,
    subtitle,
    content,
    tableStyle = TABLE_STYLES.DEFAULT,
    ...otherProps
}) {
    return (
        <TableRawHTML
            title={title}
            subtitle={subtitle}
            content={content}
            tableStyle={tableStyle}
            dangerouslySetInnerHTML={{
                __html: content,
            }}
            {...otherProps}
        />
    );
}

GeneratedTable.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    content: PropTypes.string.isRequired,
    tableStyle: PropTypes.oneOf(Object.values(TABLE_STYLES)),
};

GeneratedTable.blockKey = 'rh/generated-table';
GeneratedTable.requiresLayoutRow = true;

export default GeneratedTable;
