import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withSize } from 'react-sizeme';

import { AdTypes } from '@ratehub/base-ui';

import getAdSizeForContainer from '../functions/getAdSizeForContainer';
import AdSizes from '../definitions/AdSizes';
import Ad from './Ad';


function AdLeaderboard({
    targeting,
    overrideAdIndex,
    size,
    ...otherProps
}) {
    // size-me is expected to not be available on first render.
    const adSize = size?.width
        ? getAdSizeForContainer(size.width, AdSizes[AdTypes.Leaderboard])
        : undefined;

    return (
        <MyContainer {...otherProps}>
            <Ad
                type={AdTypes.Leaderboard}
                targeting={targeting}
                overrideAdIndex={overrideAdIndex}
                adSize={adSize}
            />
        </MyContainer>
    );
}

AdLeaderboard.propTypes = {
    targeting: PropTypes.object,
    overrideAdIndex: PropTypes.number,
    size: PropTypes.object,
};

const MyContainer = styled.div`
    display: flex;

    height: min-content;
    margin: auto;

    align-items: center;
    justify-content: center;
`;

const WrappedAdLeaderboard = withSize({ noPlaceholder: true })(AdLeaderboard);

// withSize() removes properties directly on AdLeaderboard so we need to add them *after* calling withSize()
WrappedAdLeaderboard.blockKey = 'rh/ad-leaderboard';
WrappedAdLeaderboard.requiresLayoutRow = true;

export default WrappedAdLeaderboard;