import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
    Colours,
    Sizes,
    AnchorWithIcon,
    TextAnchor,
    IconMapLazy,
    RichTextContent,
    getFGColourClassName,
} from '@ratehub/base-ui';
import classNames from 'classnames';


function TwoColumnMessageBlock({
    title,
    titleColour = Colours.BLUEBERRY_DARK,
    content,
    linkHREF,
    linkLabel,
    iconKey = 'arrow-right',
    className,
    ...otherProps
}) {
    const isAnchorEnabled = !!(linkHREF && linkLabel);

    // dynamic components
    const Icon = IconMapLazy[iconKey] ?? undefined;
    const Anchor = Icon ? AnchorWithIcon : TextAnchor;

    return (
        <TwoColumnMessageBlock.Container
            titleColour={titleColour}
            className={classNames(
                'rh-display-flex rh-justify-content-space-between rh-align-items-flex-start rh-flex-wrap',
                // offset entire container to combat optical illusion which makes it feel like the the whole component has more space below it than above it
                'rh-mt-1_5',
                className,
            )}
            {...otherProps}
        >
            <h2 className={classNames(
                'rh-title-2xl weight-bold title rh-mb-1_5',
                'rh-mt-0 rh-pr-1_5 rh-box-sizing-border-box',
                getFGColourClassName(titleColour),
            )}
            >
                {title}
            </h2>

            <div className="body rh-pt-0_75">
                <If condition={content}>
                    <RichTextContent
                        content={content}
                        hasVerticalMargins={false}
                    />
                </If>

                <If condition={isAnchorEnabled}>
                    <Anchor
                        className="link rh-mt-1_25"
                        data-name="twoColumnMessageBlock-learnMore"
                        message={linkLabel}
                        iconComponent={Icon}
                        href={linkHREF}
                    />
                </If>
            </div>
        </TwoColumnMessageBlock.Container>
    );
}

TwoColumnMessageBlock.propTypes = {
    title: PropTypes.node.isRequired,
    titleColour: PropTypes.oneOf(Object.values(Colours)),
    content: PropTypes.string.isRequired,
    linkHREF: PropTypes.string,
    linkLabel: PropTypes.string,
    iconKey: PropTypes.string,
    className: PropTypes.string,
};

TwoColumnMessageBlock.Container = styled.div`


    // DB - Keeping this note above for historical context even though these styles are now applied via rh- classes
    /* Two things going on here - 1) the margin-bottom spaces out the title from
    the body when stacked and 2) the padding-top allows the title to align
    itself slightly above the body on desktop (to offset yet another optical
    illusion). The margin & the padding combine on mobile however so these
    values have been chosen carefully to work together. */
    // > .title {
    //     margin-bottom: ${Sizes.SPACING.ONE_AND_A_HALF};
    // }
    // > .body {
    //     padding-top: ${Sizes.SPACING.THREE_QUARTERS};
    // }

    > .title {
        flex: 1 0 25%;
        min-width: 14rem;
        line-height: 1.35;
    }

    > .body {
        flex: 1 0 75%;

        > .link {
            // Entire flex container is propped open to 50px tall by the icon
            // when icon is enabled. We want to maintain this when the icon is
            // disabled too so the anchor doesn't move around as we
            // toggle icon on/off.
            //
            // Uses px as icon is sized via width/height attrs.
            min-height: 50px;
        }
    }
`;

TwoColumnMessageBlock.blockKey = 'rh/two-column-message-block';
TwoColumnMessageBlock.requiresLayoutRow = true;

export default TwoColumnMessageBlock;
