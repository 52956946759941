import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useIntl } from 'react-intl';

import { Colours, Sizes, Typography, IconArrowRight, Paragraph } from '@ratehub/base-ui';


function DateCategorizedRow({
    date,
    title,
    bodyText,
    href,
    showLinkArrow = false,
    ...otherProps
}) {
    const intl = useIntl();
    const formattedDate = intl.formatDate(date, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    });
    return (
        <DateCategorizedRow.Grid
            {...otherProps}
            role="row"
        >
            <DateCategorizedRow.Date
                dateTime={formattedDate}
                role="cell"
                suppressHydrationWarning // due to timezone differences, this could cause a hydration mismatch
            >
                {formatDisplayDate(formattedDate)}
            </DateCategorizedRow.Date>

            <DateCategorizedRow.Title
                role="cell"
                size="medium"
                dangerouslySetInnerHTML={{
                    __html: title,
                }}
            />
            <DateCategorizedRow.Anchor
                forwardedAs="a"
                role="cell"
                href={href}
                data-name="dateCategorizedRow-learnMore"
            >
                <DateCategorizedRow.Span
                    dangerouslySetInnerHTML={{
                        __html: bodyText,
                    }}
                />

                <If condition={showLinkArrow}>
                    <DateCategorizedRow.Arrow />
                </If>
            </DateCategorizedRow.Anchor>

        </DateCategorizedRow.Grid>
    );
}

DateCategorizedRow.propTypes = {
    date: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    bodyText: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    showLinkArrow: PropTypes.bool,
};

const MAX_WIDTH_BREAKPOINT = '43.75em';
const ICON_SIZE = Sizes.ICONS.S;

DateCategorizedRow.Date = styled.time`
    font-family: ${Typography.FONT_FAMILY_STACK};
    font-size: ${Sizes.FONTS.S};
    font-weight: ${Typography.WEIGHTS.BOLD};

    margin-top: 2px;

    @media(max-width: ${MAX_WIDTH_BREAKPOINT}) {
        font-size: ${Sizes.FONTS.XS};
    }
`;

DateCategorizedRow.Title = styled(Paragraph)`
    color: ${Colours.STONE_DARKEST};

    margin-top: 0;
    margin-bottom: ${Sizes.SPACING.QUARTER};
`;

DateCategorizedRow.Arrow = styled(IconArrowRight)`
    width: ${ICON_SIZE};
    height: ${ICON_SIZE};

    position: absolute;
    top: 0;
    right: 0;
`;

DateCategorizedRow.Span = styled.span``;

DateCategorizedRow.Anchor = styled(Paragraph)`
    font-weight: ${Typography.WEIGHTS.BOLD};
    color: ${Colours.BLACKBERRY};

    margin-top: 0;
    margin-bottom: 0;

    text-decoration: none;
    outline: none;

    position: relative;
    padding-right: calc(${ICON_SIZE} + ${Sizes.SPACING.ONE});

    transition: color 300ms;

    &:hover,
    &:focus {
        color: ${Colours.BLUEBERRY};

        ${DateCategorizedRow.Arrow} {
            stroke: ${Colours.BLUEBERRY};
        }
    }

    @media(max-width: ${MAX_WIDTH_BREAKPOINT}) {
        font-size: ${Sizes.FONTS.S};
    }
`;

DateCategorizedRow.Grid = styled.div`
    display: grid;

    grid-template-columns: 15% auto;
    grid-column-gap: ${Sizes.SPACING.TWO};

    grid-template-rows: repeat(2, auto);

    padding: ${Sizes.SPACING.TWO} 0;
    border-bottom: 2px solid ${Colours.STONE};

    ${DateCategorizedRow.Date} {
        grid-row: 2;
        grid-column: 1;
    }

    ${DateCategorizedRow.Title} {
        grid-row: 1;
        grid-column: 2;
    }

    ${DateCategorizedRow.Anchor} {
        grid-row: 2;
        grid-column: 2;
    }

    @media(max-width: ${MAX_WIDTH_BREAKPOINT}) {
        grid-template-columns: 20% auto;

        padding: ${Sizes.SPACING.ONE_AND_A_HALF} 0;
    }
`;

/**
 * formatDate
 * Formats date string to a desired display format
 * @param {string} date expected format is MM/DD/YYYY
 */
function formatDisplayDate(date) {
    return date.replace(/-/g, '. ');
}

export default DateCategorizedRow;
