import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';

import {
    CategoryTagPropType,
    LayoutRow,
    getBlogCategoryPath,
} from '@ratehub/web-components';

import getBlogTagPath from '../functions/getBlogTagPath';


function BlogTaxonomyList({ categories, tags, ...otherProps }) {
    const intl = useIntl();

    return (
        <LayoutRow
            className="rh-layout-default rh-mb-2"
            includeContentPadding={false}
            as="aside"
            {...otherProps}
        >
            <table className="list">
                <tbody>
                    <If condition={categories}>
                        <tr>
                            <th className="weight-regular rh-pt-0_5 rh-pr-1_5 rh-text-align-left rh-text-s">
                                <FormattedMessage
                                    id="web-server.BlogTaxonomyList.categoriesTitle"
                                    defaultMessage="Categories"
                                />
                            </th>

                            <td>
                                <For
                                    each="category"
                                    of={categories}
                                >
                                    <a
                                        key={`category-${category.label}`}
                                        className="rh-display-inline-block rh-tag"
                                        href={getBlogCategoryPath(category, intl.locale)}
                                    >
                                        {category.label}
                                    </a>
                                </For>
                            </td>
                        </tr>
                    </If>

                    <If condition={tags}>
                        <tr>
                            <th className="weight-regular rh-pt-0_5 rh-pr-1_5 rh-text-align-left rh-text-s">
                                <FormattedMessage
                                    id="web-server.BlogTaxonomyList.tagsTitle"
                                    defaultMessage="Tags"
                                />
                            </th>

                            <td>
                                <For
                                    each="tag"
                                    of={tags}
                                >
                                    <a
                                        key={`tag-${tag.label}`}
                                        className="rh-display-inline-block rh-tag"
                                        href={getBlogTagPath(tag, intl.locale)}
                                    >
                                        {tag.label}
                                    </a>
                                </For>
                            </td>
                        </tr>
                    </If>
                </tbody>
            </table>
        </LayoutRow>
    );
}

BlogTaxonomyList.propTypes = {
    categories: PropTypes.arrayOf(CategoryTagPropType),
    tags: PropTypes.arrayOf(CategoryTagPropType),
};

export default BlogTaxonomyList;
