/*
 *  Before removing an experiment from this file, read
 *  https://github.com/ratehub/front-end/blob/main/docs/experiments.md#how-to-remove-an-experiment
 */


// https://cms.ratehub.dev/wp-admin/edit.php?post_type=experiments
// https://cms.ratehub.ca/wp-admin/edit.php?post_type=experiments

const EXPERIMENTS = {
    // added 2024-Aug-08
    incentiveCompactPage: 'cc-incentive-compact-page',  // to be removed by ccdep-37xx
};

export default EXPERIMENTS;
