import React, {
    useState,
    useEffect,
    useCallback,
} from 'react';
import { useIntl, defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { rgba, desaturate } from 'polished';
import { useEmblaCarousel } from 'embla-carousel/react';

import { Sizes, RemoveDefaultButtonStyles, Colours } from '@ratehub/base-ui';


const ALIGNMENT = {
    START: 'start',
    CENTER: 'center',
};

const VARIANTS = {
    SINGLE: 'single',
    MULTIPLE: 'multiple',
};

function Carousel({
    alignment = ALIGNMENT.START,
    startIndex = 0,
    as = 'div',
    hideArrows = false,
    hideDots = false,
    isHidden = false,
    overflow = 'visible',
    gap = '0',
    isLoopEnabled = false,
    variant = VARIANTS.MULTIPLE,
    childContainerDataName = 'carouselList',
    children,
    ...otherProps
}) {
    // hideDots prop only respected when variant === VARIANTS.MULTIPLE
    const hideDotsComputed = variant === VARIANTS.MULTIPLE
        ? hideDots
        : true;

    const intl = useIntl();
    const Element = as;

    const [ viewportRef, embla ] = useEmblaCarousel({
        startIndex,
        align: alignment,
        loop: isLoopEnabled, // TIP: looping smoothly requires position: relative on all children/slides.
    });

    const [ isPrevBtnEnabled, setIsPrevBtnEnabled ] = useState(false);
    const [ isNextBtnEnabled, setIsNextBtnEnabled ] = useState(false);
    const [ selectedIndex, setSelectedIndex ] = useState(0);
    const [ scrollSnaps, setScrollSnaps ] = useState([]);

    const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [ embla ]);
    const scrollNext = useCallback(() => embla && embla.scrollNext(), [ embla ]);
    const scrollTo = useCallback((index) => embla && embla.scrollTo(index), [ embla ]);

    const onDotSelect = useCallback(() => {
        if (!embla) {
            return;
        }
        setSelectedIndex(embla.selectedScrollSnap());
        setIsPrevBtnEnabled(embla.canScrollPrev());
        setIsNextBtnEnabled(embla.canScrollNext());
    }, [ embla ]);

    useEffect(() => {
        function recalculateDots() {
            const newScrollSnaps = embla.scrollSnapList();
            setScrollSnaps(newScrollSnaps);

            /* Embla doesn't allow us to change options via the prop,
                      if there is nothing to scroll we want to disable dragging */

            embla.reInit({
                draggable: newScrollSnaps.length > 1,
            });
        }

        if (!embla || isHidden) {
            return;
        }

        embla.reInit(
            // If true, this clears extra space among slides
            alignment === 'start'
                ? { containScroll: true }
                : {},
        );
        onDotSelect();
        recalculateDots();

        embla.on('select', onDotSelect);
        embla.on('resize', recalculateDots);
    }, [ embla, onDotSelect, isHidden, children ]);

    return (
        <Container
            // when in SINGLE variant, all controls are left-justified when dots are hidden.
            justifyControlsSingle={hideDotsComputed ? 'flex-start' : 'center'}
            $overflow={overflow}
            $gap={gap}
            variant={variant}
            {...otherProps}
        >
            <div // this element is the container within which the slides move
                className="carousel"
                ref={viewportRef}
            >
                <Element // this element gets translated left/right
                    className="carousel__child_container"
                    data-name={childContainerDataName}
                >
                    {children}
                </Element>
            </div>

            <If condition={scrollSnaps.length > 1}>
                <div className="carousel__button-container">
                    <If condition={!hideArrows}>
                        <button
                            type="button"
                            className="carousel__button carousel__button_back rh-mr-0_5 rh-cursor-pointer"
                            data-name="carousel-back"
                            aria-label={intl.formatMessage(MESSAGES.BACK)}
                            disabled={!isPrevBtnEnabled}
                            onClick={scrollPrev}
                        >
                            <svg
                                viewBox="0 0 20 31"
                                width="20"
                                height="31"
                            >
                                <path
                                    d="M17 28.083L3 15.36 17 2.635"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                    </If>

                    <If condition={!hideDotsComputed}>
                        <div className="carousel__dots rh-display-flex">
                            <For
                                each="dot"
                                index="index"
                                of={scrollSnaps}
                            >
                                <button
                                    key={index}
                                    type="button"
                                    className="carousel__dots_button rh-cursor-pointer"
                                    data-name="carousel-dots"
                                    aria-label={intl.formatMessage(MESSAGES.NAVIGATE_TO, {
                                        index,
                                    })}
                                    onClick={() => scrollTo(index)}
                                    data-selected={index === selectedIndex}
                                />
                            </For>
                        </div>
                    </If>

                    <If condition={!hideArrows}>
                        <button
                            type="button"
                            className="carousel__button carousel__button_next rh-ml-0_5 rh-cursor-pointer"
                            data-name="carousel-next"
                            aria-label={intl.formatMessage(MESSAGES.NEXT)}
                            disabled={!isNextBtnEnabled}
                            onClick={scrollNext}
                        >
                            <svg
                                viewBox="0 0 20 31"
                                width="20"
                                height="31"
                            >
                                <path
                                    d="M3 2.617l14 12.724L3 28.065"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                    </If>
                </div>
            </If>
        </Container>
    );
}


Carousel.propTypes = {
    children: PropTypes.node.isRequired,
    hideDots: PropTypes.bool,
    hideArrows: PropTypes.bool,
    alignment: PropTypes.oneOf(Object.values(ALIGNMENT)),
    startIndex: PropTypes.number,
    as: PropTypes.string,
    isHidden: PropTypes.bool,
    overflow: PropTypes.oneOf([ 'hidden', 'visible' ]),
    gap: PropTypes.string, // any unit-ed CSS length.
    isLoopEnabled: PropTypes.bool,
    variant: PropTypes.oneOf(Object.values(VARIANTS)),
    childContainerDataName: PropTypes.string,
};

const Container = styled.div`
    position: relative;

    > .carousel {
        /* We often place Carousel inside a LayoutRow, which handles
        overflow settings for us, clipping the cards at the edge of the
        LayoutRow. However, without one, we need *something* to clip the
        children, otherwise we'll get a huge horizontal scrollbar. */
        overflow: ${props => props.$overflow};

        &.is-draggable {
        /* IE11 responds to move, not grab */
            cursor: move;
            cursor: grab;
        }

        &.is-dragging {
            cursor: grabbing;
        }

        > .carousel__child_container {
            display: flex;
            gap: ${props => props.$gap};

            margin: 0;
            padding: 0;

            /* The embla library calculates the number of dots when the widths
                of the content is larger than the width of this container.
                When there are 3 cards, there is a case where the widths of all
                3 cards ends up a fraction of a pixel larger than the container width
                (due to JS rounding their widths to 2 decimal places), causing the library
                to show two dots in cases where there should be one.
                Adding 1px of padding ensures the coontainer width is larger
                than the content width */
            padding-right: 1px;
        }
    }

    ${props => props.variant === VARIANTS.MULTIPLE
        ? BUTTON_CONTAINER_STYLES_MULTIPLE
        : BUTTON_CONTAINER_STYLES_SINGLE};
`;

const BUTTON_CONTAINER_STYLES_MULTIPLE = css`
    > .carousel__button-container {
        display: flex;
        justify-content: ${(props) => props.justifyControlsSingle};
        align-items: center;
        flex-wrap: nowrap;

        margin-top: ${Sizes.SPACING.TWO};

        > .carousel__button {
            ${RemoveDefaultButtonStyles};

            &.carousel__button_back,
            &.carousel__button_next {
                > svg {
                    width: 20px;
                    height: 31px;

                    stroke-width: 2;
                    stroke: ${Colours.BLUEBERRY_DARK};
                    fill: none;

                    transition: stroke 300ms;
                }

                &:hover:enabled,
                &:focus:enabled,
                &:active:enabled {
                    > svg {
                        stroke: ${desaturate(0.6, Colours.BLUEBERRY_DARKEST)};
                    }
                }

                &:disabled {
                    cursor: default;

                    > svg {
                        stroke: ${rgba(Colours.BLUEBERRY_DARK, 0.25)};
                    }
                }
            }

            &.carousel__button_back,
            &.carousel__button_next {
                margin-top: 0.2rem;
            }
        }

        > .carousel__dots {
            align-items: center;
            justify-content: center;

            margin: 0 ${Sizes.SPACING.HALF};

            .carousel__dots_button {
                ${RemoveDefaultButtonStyles};

                width: ${Sizes.SPACING.THREE_QUARTERS};
                height: ${Sizes.SPACING.THREE_QUARTERS};
                margin: 0 ${Sizes.SPACING.THREE_QUARTERS};

                border-radius: 50%;
                background-color: ${rgba(Colours.BLUEBERRY_DARK, 0.4)};

                transition: background-color 300ms;

                &:hover {
                    background-color: ${Colours.BLUEBERRY_DARK};
                }

                &[data-selected="true"] {
                    background-color: ${Colours.BLUEBERRY_DARK};

                    &:hover {
                        background-color: ${Colours.BLUEBERRY_DARKEST};
                    }
                }

                &:focus,
                &:active {
                    background-color: ${Colours.BLUEBERRY_DARKEST};
                }
            }
        }
    }
`;

const BUTTON_CONTAINER_STYLES_SINGLE = css`
    > .carousel__button-container {
        position: absolute;
        top: 50%;
        left: 10%;
        right: 10%;
        transform: translateY(-50%);

        display: flex;
        justify-content: space-between;

        > .carousel__button {
            ${RemoveDefaultButtonStyles};
            cursor: pointer;

            &.carousel__button_back,
            &.carousel__button_next {
                > svg {
                    width: auto;
                    height: 50px;

                    stroke-width: 2;
                    stroke: ${Colours.BLUEBERRY_DARK};
                    fill: none;

                    transition: stroke 300ms;
                }

                &:hover:enabled,
                &:focus:enabled,
                &:active:enabled {
                    > svg {
                        stroke: ${desaturate(0.6, Colours.BLUEBERRY_DARKEST)};
                    }
                }

                &:disabled {
                    cursor: default;

                    > svg {
                        stroke: ${rgba(Colours.BLUEBERRY_DARK, 0.25)};
                    }
                }
            }
        }
    }
`;

const MESSAGES = defineMessages({
    BACK: {
        id: 'WebComponents.Carousel.BackLabel',
        defaultMessage: 'Back',
    },
    NEXT: {
        id: 'WebComponents.Carousel.NextLabel',
        defaultMessage: 'Next',
    },
    NAVIGATE_TO: {
        id: 'WebComponents.Carousel.NavigateToCardLabel',
        defaultMessage: 'Navigate to card {index}',
    },
});

Carousel.VARIANTS = VARIANTS;

Carousel.blockKey = 'rh/carousel';

export default Carousel;
export { VARIANTS };
