import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import classNames from 'classnames';

import {
    IconChooser,
    IconMapLazy,
    StarRating,
    Sizes,
    Colours,
    MessagePropType,
    RichTextContent,
    messageToString,
    usePageSettings,
} from '@ratehub/base-ui';

import HeadingPropTypes from '../definitions/HeadingPropTypes';
import HeadingBlock from './HeadingBlock';
import AuthorStamp from './AuthorStamp';


function RichTextBlock({
    iconKey,
    iconAlignment = RichTextBlock.ALIGNMENTS.CENTER,
    heading,
    content,
    variant = VARIANTS.DARK,

    showAuthor = false,

    selectedDate,
    dateDisplayType,

    starRating,
    starMessage,

    hasVerticalMargins = true,
    className,
    dataName,

    ...otherProps
}) {
    const intl = useIntl();
    const { author, modifiedDate, publishedDate } = usePageSettings();

    const isLegacyVariant = variant === VARIANTS.DARK || variant === VARIANTS.LIGHT;
    const hasIcon = iconKey && iconKey !== 'none' && iconAlignment;

    return (
        <RichTextBlock.Container
            iconAlignment={iconAlignment}
            className={classNames(
                'rh-mx-auto',
                'rh-max-width-800px',
                className,
            )}
            data-name={dataName}
            {...otherProps}
        >
            <If condition={!isLegacyVariant && hasIcon}>
                <div className="icon-container rh-px-0 rh-display-flex">
                    <IconChooser
                        size="2XL"
                        iconKey={iconKey}
                        variant={variant}
                        stroke={RichTextBlock.THEMES[variant]?.ICON_STROKE}
                    />
                </div>
            </If>

            <HeadingBlock
                title={heading?.title}
                introduction={isLegacyVariant ? heading?.introduction : undefined}
                variant={isLegacyVariant ? variant : undefined}
                isCentered={heading?.isCentered}
            />

            <If condition={showAuthor && author}>
                <AuthorStamp
                    modifiedDate={dateDisplayType === AUTHOR_STAMP_DATE_TYPE.MODIFIED ? modifiedDate : undefined}
                    publishedDate={dateDisplayType === AUTHOR_STAMP_DATE_TYPE.PUBLISHED ? publishedDate : undefined}
                    selectedDate={dateDisplayType === AUTHOR_STAMP_DATE_TYPE.SELECTED ? selectedDate : undefined}
                    variant={isLegacyVariant
                        ? variant
                        : variant === VARIANTS.BLUEBERRY_DARK
                            ? VARIANTS.LIGHT
                            : undefined
                    }
                    author={author}
                />
            </If>

            <If condition={starRating >= 0}>
                <figure className="star-rating-wrapper rh-display-flex rh-mx-0 rh-mt-0 rh-mb-1">
                    <StarRating
                        starSize={Sizes.ICONS['2XS']}
                        ratingPercent={starRating}
                    />

                    <If condition={starMessage}>
                        <figcaption
                            className={classNames(
                                'rh-my-0 rh-mr-0 rh-ml-0_5',
                                getStarCaptionClass(variant),
                            )}
                        >
                            {messageToString(starMessage, intl)}
                        </figcaption>
                    </If>
                </figure>
            </If>

            <If condition={content}>
                <RichTextContent
                    variant={RichTextBlock.THEMES[variant].RICH_TEXT_CONTENT_VARIANT}
                    content={content}
                    hasVerticalMargins={hasVerticalMargins}
                />
            </If>
        </RichTextBlock.Container>
    );
}


const VARIANTS = {
    DARK: 'dark',
    LIGHT: 'light',
    BLUEBERRY_LIGHTEST: 'blueberry-lightest',
    MINT_LIGHTEST: 'mint-lightest',
    STONE_LIGHTEST: 'stone-lightest',
    BLUEBERRY_DARK: 'blueberry-dark',
};

RichTextBlock.ALIGNMENTS = {
    LEFT: 'flex-start',
    CENTER: 'center',
    RIGHT: 'flex-end',
};

RichTextBlock.THEMES = {
    [VARIANTS.DARK]: {
        BACKGROUND_COLOUR: Colours.COCONUT,
        FONT_COLOUR: Colours.BLACKBERRY,
        RICH_TEXT_CONTENT_VARIANT: 'dark',
        ICON_STROKE: Colours.BLACKBERRY,
        PRIMARY_ANCHOR_VARIANT: 'blueberry-dark',
        ALT_ANCHOR_VARIANT: 'dark',
        STAR_CAPTION_CLASS: 'rh-fg-blackberry',
    },

    [VARIANTS.LIGHT]: {
        BACKGROUND_COLOUR: Colours.BLACKBERRY,
        FONT_COLOUR: Colours.COCONUT,
        RICH_TEXT_CONTENT_VARIANT: 'light',
        ICON_STROKE: Colours.COCONUT,
        PRIMARY_ANCHOR_VARIANT: 'light',
        ALT_ANCHOR_VARIANT: 'dark',
        STAR_CAPTION_CLASS: 'rh-fg-coconut',
    },

    [VARIANTS.BLUEBERRY_LIGHTEST]: {
        BACKGROUND_COLOUR: Colours.BLUEBERRY_LIGHTEST,
        FONT_COLOUR: Colours.BLUEBERRY_DARK,
        RICH_TEXT_CONTENT_VARIANT: 'dark',
        ICON_STROKE: Colours.BLUEBERRY_DARK,
        PRIMARY_ANCHOR_VARIANT: 'blueberry-dark',
        ALT_ANCHOR_VARIANT: 'dark',
        STAR_CAPTION_CLASS: 'rh-fg-blackberry',
    },

    [VARIANTS.MINT_LIGHTEST]: {
        BACKGROUND_COLOUR: Colours.MINT_LIGHTEST,
        FONT_COLOUR: Colours.BLUEBERRY_DARK,
        RICH_TEXT_CONTENT_VARIANT: 'dark',
        ICON_STROKE: Colours.BLUEBERRY_DARK,
        PRIMARY_ANCHOR_VARIANT: 'mint-dark',
        ALT_ANCHOR_VARIANT: 'dark',
        STAR_CAPTION_CLASS: 'rh-fg-blackberry',
    },

    [VARIANTS.STONE_LIGHTEST]: {
        BACKGROUND_COLOUR: Colours.STONE_LIGHTEST,
        FONT_COLOUR: Colours.BLUEBERRY_DARK,
        RICH_TEXT_CONTENT_VARIANT: 'dark',
        ICON_STROKE: Colours.BLUEBERRY_DARK,
        PRIMARY_ANCHOR_VARIANT: 'blueberry-dark',
        ALT_ANCHOR_VARIANT: 'dark',
        STAR_CAPTION_CLASS: 'rh-fg-blackberry',
    },

    [VARIANTS.BLUEBERRY_DARK]: {
        BACKGROUND_COLOUR: Colours.BLUEBERRY_DARK,
        FONT_COLOUR: Colours.COCONUT,
        RICH_TEXT_CONTENT_VARIANT: 'light',
        ICON_STROKE: Colours.COCONUT,
        PRIMARY_ANCHOR_VARIANT: 'light',
        ALT_ANCHOR_VARIANT: 'light',
        STAR_CAPTION_CLASS: 'rh-fg-coconut',
    },
};

const AUTHOR_STAMP_DATE_TYPE = {
    PUBLISHED: 'published',
    MODIFIED: 'modified',
    SELECTED: 'selected',
};

RichTextBlock.propTypes = {
    iconKey: PropTypes.oneOf(Object.keys(IconMapLazy)),
    iconAlignment: PropTypes.oneOf(Object.values(RichTextBlock.ALIGNMENTS)),
    heading: HeadingPropTypes,
    content: PropTypes.string,
    variant: PropTypes.oneOf(Object.values(VARIANTS)),

    showAuthor: PropTypes.bool,
    selectedDate: PropTypes.string,
    dateDisplayType: PropTypes.oneOf(Object.values(AUTHOR_STAMP_DATE_TYPE)),

    starRating: PropTypes.number,
    starMessage: MessagePropType,

    hasVerticalMargins: PropTypes.bool,
    className: PropTypes.string,
    dataName: PropTypes.string,
};

function getStarCaptionClass(variant) {
    return RichTextBlock.THEMES[variant]?.STAR_CAPTION_CLASS;
}


/**
 * Currently used directly by the edit function in the RichText WP block.
 * Could use a refactor in the future.
 */
RichTextBlock.Container = styled.div`
    .icon-container {
        justify-content: ${props => props.iconAlignment};
    }

    > .star-rating-wrapper {
        align-items: center;
    }
`;

RichTextBlock.blockKey = 'rh/rich-text-block';
RichTextBlock.hasHeading = true;
RichTextBlock.requiresLayoutRow = true;
RichTextBlock.isServerOnly = true;
RichTextBlock.outerTagName = 'div';

RichTextBlock.AUTHOR_STAMP_DATE_TYPE = AUTHOR_STAMP_DATE_TYPE;

export default RichTextBlock;
