import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import toKebabCase from '../functions/toKebabCase';
import MessagePropType from '../definitions/MessagePropType';
import TabListButton from './TabListButton';


{/* @TODO: This component should be called VerticalTabs to 
    match HorizontalTabs. TabContainer <Choose>s between the
    two when you change the orientation from horizontal to
    vertical and vice-versa. */}

function TabList({
    tabs = [],
    activeTabIndex = 0, // pass this to make TabList controlled
    initialTabIndex = 0, // for use when uncontrolled only
    onTabChange,
    tabIdPrefix,
    tabPanelIdPrefix,
    variant = 'dark',
    size = 'medium',
    ...otherProps
}) {
    // Must be an index
    const [ activeTab, setActiveTab ] = useState(activeTabIndex ?? initialTabIndex);

    const isControlled = typeof activeTabIndex === 'number';

    // Update activeTab state when the controlled prop changes
    useEffect(() => {
        if (isControlled) {
            setActiveTab(activeTabIndex);
        }
    }, [ activeTabIndex ]);

    function handleTabClick(index) {
        if (!isControlled) {
            setActiveTab(index);
        }
        onTabChange && onTabChange(index);
    }

    function getTabId(tab) {
        return tabIdPrefix && tab.title ? `${tabIdPrefix}_${toKebabCase(tab.title)}` : undefined;
    }

    function getTabPanelId(tab) {
        return tabPanelIdPrefix && tab.title ? `${tabPanelIdPrefix}_${toKebabCase(tab.title)}` : undefined;
    }

    return (
        <Container
            role="tablist"
            {...otherProps}
        >
            <For
                each="tab"
                index="index"
                of={tabs}
            >
                <li
                    key={`${index}-${toKebabCase(tab.title)}`}
                    role="presentation"
                >
                    <TabListButton
                        id={getTabId(tab)}
                        onClick={() => handleTabClick(index)}
                        label={tab.title}
                        subTitle={tab?.subTitle}
                        isSelected={activeTab === index}
                        role="tab"
                        aria-selected={activeTab === index}
                        aria-controls={getTabPanelId(tab)}
                        variant={variant}
                        size={size}
                    />
                </li>
            </For>
        </Container>
    );
}

TabList.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            title: MessagePropType.isRequired,
            subTitle: MessagePropType,
        }),
    ),
    activeTabIndex: PropTypes.number,
    initialTabIndex: PropTypes.number,
    onTabChange: PropTypes.func,
    tabIdPrefix: PropTypes.string,
    tabPanelIdPrefix: PropTypes.string,
    variant: PropTypes.oneOf([
        'light',
        'dark',
    ]),
    size: PropTypes.oneOf([
        'small',
        'medium',
        'large',
    ]),
};

const Container = styled.ul`
    margin: 0;
    padding: 0;

    > li {
        list-style: none;
    }
`;

export default observer(TabList);
