import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

import { Colours, Sizes, ImageSizes, MessagePropType, AnchorWithIcon } from '@ratehub/base-ui';

import TagPropType from '../definitions/TagPropType';
import ImageCollectionPropType from '../definitions/ImageCollectionPropType';
import VisualElement from './VisualElement';


const COLUMN_SIZES = {
    SMALL: 'small',
    LARGE: 'large',
};

function FactBlock({
    iconKey,

    imageCollection,
    imageAlt = '',
    imagePositionX = '50%',
    imagePositionY = '50%',

    title = '',
    message,
    statisticText,

    href,
    hrefText,
    isImageAndTitleALink = false,

    tags,
    isArticle = false,
    type = FactBlockType.DEFAULT,

    columnSize = COLUMN_SIZES.LARGE,
    className,

    ...otherProps
}) {
    const isCardType = type === FactBlockType.CARD;

    const visualClassName = classNames({
        'icon-container': iconKey && iconKey !== 'none',
        'image-container rh-width-100 rh-overflow-hidden': !iconKey || iconKey === 'none',
    });

    const FactBlockInnerContainer = isArticle ? 'article' : 'div';
    const factBlockInnerClass = classNames(
        'rh-display-flex rh-flex-direction-column rh-height-100p rh-m-auto fact-block-inner-container',
        { 'fact-block-card-container rh-px-2_5 rh-py-0 rh-align-items-center rh-border-radius-8px rh-bg-coconut': isCardType },
    );

    // Optional anchor
    const ComponentWrapper = isImageAndTitleALink ? 'a' : Fragment;
    const componentWrapperProps = isImageAndTitleALink
        ? {
            href: href,
            // Can't rely on the image alt text here to be useful, so might as well hide this link from focus
            tabIndex: -1,
            'aria-hidden': true,
        }
        : undefined;

    const isVisualElementEnabled = imageCollection || (iconKey && iconKey !== 'none');

    return (
        <FactBlock.ListItem
            className={classNames(
                {
                    'rh-mb-3 rh-ml-3': !isCardType,
                    'rh-mb-1 rh-ml-2': isCardType,
                },
                'rh-list-style-type-none ',
                className
            )}
            imagePositionX={imagePositionX}
            imagePositionY={imagePositionY}
            type={type}
            columnSize={columnSize}
            {...otherProps}
        >
            <FactBlockInnerContainer className={factBlockInnerClass}>
                <ComponentWrapper {...componentWrapperProps}>
                    <Choose>
                        <When condition={statisticText}>
                            <span
                                className={classNames(
                                    {
                                        'rh-text-align-center': isCardType,
                                    },

                                    'rh-m-0',
                                    'rh-title-2xl',
                                    'leading-xl',
                                    'rh-fg-blueberry-dark',
                                    'weight-bold',
                                )}
                            >
                                {statisticText}
                            </span>
                        </When>
                        <Otherwise>
                            <If condition={isVisualElementEnabled}>
                                <div className={classNames({
                                    'rh-icon-2xl': visualClassName.includes('icon-container') && type === FactBlockType.DEFAULT,
                                    'rh-icon-m': visualClassName.includes('icon-container') && isCardType,
                                }, visualClassName)}
                                >
                                    <VisualElement
                                        iconKey={iconKey}
                                        iconStroke={Colours.BLUEBERRY_DARK}
                                        imageCollection={formatImageCollection(imageCollection, imageAlt)}
                                    />
                                </div>
                            </If>
                        </Otherwise>
                    </Choose>
                </ComponentWrapper>

                <div
                    className={classNames('title-container', {
                        'with-vertical-margin': isImageAndTitleALink,
                        'rh-text-align-center rh-mt-0_75': isCardType,
                    })}
                >
                    <ComponentWrapper
                        {...componentWrapperProps}
                    >
                        <p
                            className={classNames(
                                {
                                    'rh-mb-1_75': isCardType,
                                    'rh-mb-0_75': !isCardType,
                                },
                                'rh-title-s title rh-mt-0 rh-fg-blackberry'
                            )}
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                                __html: title,
                            }}
                        />
                    </ComponentWrapper>
                </div>

                <If condition={message}>
                    <p
                        className={classNames({
                            'rh-mb-0 rh-text-align-center rh-flex-grow-1 rh-flex-shrink-1': isCardType,
                            'rh-mb-1': !isCardType,
                        }, 'rh-mt-0  rh-text-m')}
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: message,
                        }}
                    />
                </If>

                <If condition={tags}>
                    <div className="rh-display-flex rh-flex-wrap rh-mb-1 tags-container">
                        <For
                            each="tag"
                            of={tags}
                        >
                            <a
                                key={tag.title}
                                className="tag rh-tag rh-display-block"
                                {...tag}
                            >
                                {tag.title}
                            </a>
                        </For>
                    </div>
                </If>

                <If condition={href}>
                    <AnchorWithIcon
                        className={classNames(
                            { 'rh-pt-1': isCardType },
                            'rh-mt-auto',
                        )}
                        href={href}
                        message={hrefText}
                        dataName="factBlockItem-learnMore-cta"
                    />
                </If>
            </FactBlockInnerContainer>
        </FactBlock.ListItem>
    );
}

const FactBlockType = {
    DEFAULT: 'default',
    CARD: 'card',
};

FactBlock.propTypes = {
    iconKey: PropTypes.string,

    imageCollection: ImageCollectionPropType,
    imageAlt: PropTypes.string,
    imagePositionX: PropTypes.string,
    imagePositionY: PropTypes.string,

    statisticText: PropTypes.string,
    title: PropTypes.string,
    message: PropTypes.string,

    href: PropTypes.string,
    hrefText: MessagePropType,
    isImageAndTitleALink: PropTypes.bool,

    tags: PropTypes.arrayOf(PropTypes.shape(TagPropType)),
    isArticle: PropTypes.bool,

    type: PropTypes.oneOf(Object.values(FactBlockType)),

    columnSize: PropTypes.oneOf(Object.values(COLUMN_SIZES)),
    className: PropTypes.string,
};

FactBlock.ListItem = styled.li`
    flex-basis: ${props => getFlexBasis(props.type, props.columnSize)};
    flex-grow: 1;

    > .fact-block-inner-container {
        max-width: 29.688em;

        .image-container {
            height: 17em;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: ${props => props.imagePositionX} ${props => props.imagePositionY};
                border-radius: 0.5em;
            }
        }

        > .title-container {
            &.with-vertical-margin {
                margin-top: ${Sizes.SPACING.ONE_AND_A_HALF};
                margin-bottom: ${Sizes.SPACING.ONE_AND_A_HALF};
            }

            > a {
                text-decoration: none;
                outline: none;

                &:hover,
                &:focus {
                    > .title {
                        color: ${Colours.BLUEBERRY};
                        box-shadow: inset 0 -2px ${Colours.BLUEBERRY};
                    }
                }

                > .title {
                    margin-top: 0;
                    margin-bottom: 0;
                    transition: color 0.3s, box-shadow 0.3s;

                    display: inline;
                }
            }
        }

        > .tags-container {
            margin-left: -${Sizes.SPACING.QUARTER};
        }
    }

    > .fact-block-card-container {
        min-width: ${props => (props.columnSize === COLUMN_SIZES.LARGE ? '18.75em' : '10em')};
        max-width: 37.8125em;

        box-shadow: 0px 2px 11px 0px ${Colours.STONE};

        /* GOAL: trying to maintain equal negative space at very top and bottom
        of card, regardless of which items are showing. Very much like
        padding-top / padding-bottom, but setting those causes the parent <li>'s
        calculated height to not match the total height of
        .fact-block-card-container */
        & > *:first-child {
            margin-top: ${Sizes.SPACING.THREE};
        }
        & > *:last-child {
            margin-bottom: ${Sizes.SPACING.TWO_AND_A_HALF};
        }
    }
`;

function getFlexBasis(type, columnSize) {
    const FLEX_BASES = {
        [FactBlockType.DEFAULT]: {
            [COLUMN_SIZES.SMALL]: '14em',
            [COLUMN_SIZES.LARGE]: '21.875em',
        },
        [FactBlockType.CARD]: {
            [COLUMN_SIZES.SMALL]: '16em',
            [COLUMN_SIZES.LARGE]: '29.875em',
        },
    };

    return FLEX_BASES[type]?.[columnSize];
}

function formatImageCollection(imageCollection) {
    const sizes = imageCollection?.sizes;

    return sizes
        ? {
            sizes: [
                {
                    url: sizes?.[ImageSizes.M]?.url || sizes[ImageSizes.FULL].url,
                    breakpoint: '(min-width: 430px) and (min-resolution: 192dpi)',
                    mime: sizes?.[ImageSizes.M]?.['mime-type'] || sizes[ImageSizes.FULL]?.['mime-type'],

                },
                {
                    url: sizes?.[ImageSizes.S]?.url || sizes[ImageSizes.FULL].url,
                    breakpoint: '(max-width: 430px)',
                    mime: sizes?.[ImageSizes.S]?.['mime-type'] || sizes[ImageSizes.FULL]?.['mime-type'],
                },
            ],
            fallback: sizes?.[ImageSizes.S]?.url || sizes[ImageSizes.FULL].url,
            width: sizes?.[ImageSizes.S]?.width || sizes[ImageSizes.FULL].width,
            height: sizes?.[ImageSizes.S]?.height || sizes[ImageSizes.FULL].height,
            alt: '',
            mime: sizes?.[ImageSizes.S]?.['mime-type'] || sizes[ImageSizes.FULL]?.['mime-type'] || imageCollection.mime,
        }
        : undefined;
}

FactBlock.blockKey = 'rh/fact-block';

export { FactBlockType, COLUMN_SIZES };
export default FactBlock;
