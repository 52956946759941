import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { Sizes, Colours, Typography } from '@ratehub/base-ui';


const VARIANTS = {
    LIGHT: 'light',
    DARK: 'dark',
    SIDEBAR: 'sidebar',
};

const IMAGE_POSITIONS = {
    LEFT: 'left',
    RIGHT: 'right',
};

function LinkBlockAnchor({
    variant = VARIANTS.DARK,
    url,
    title,
    isActive = false,
    imgSrc,
    imgAlt,
    imgWidth,
    imgHeight,
    imgPosition = IMAGE_POSITIONS.LEFT,
    className,
    ...otherProps
}) {
    const hasImage = !!(imgSrc && imgAlt && imgWidth && imgHeight);

    // Cannot allow the anchor to be a flex item or it will mess up it's hover
    // styles horribly. This provides a flex item to wrap it with if necessary.
    const AnchorContainer = hasImage ? AnchorWrapperDiv : React.Fragment;

    return (
        <Container
            className={classNames({
                isActive: isActive,
                isFlex: hasImage,

                'variant-dark rh-mb-0_75': variant === VARIANTS.DARK,
                'variant-light rh-mb-0_75': variant === VARIANTS.LIGHT,
                'variant-sidebar rh-mb-0': variant === VARIANTS.SIDEBAR,
            })}
        >

            {/* NOTE: We are setting a typography className on images so the alt
            text doesn't overflow the layout when shown visually (like when
            images don't load). These images are very small so we're using a
            small font-size. */}

            <If condition={hasImage && imgPosition === IMAGE_POSITIONS.LEFT}>
                <LazyLoadImage
                    className="image rh-text-2xs"
                    src={imgSrc}
                    alt={imgAlt}
                    width={imgWidth}
                    height={imgHeight}
                />
            </If>

            <AnchorContainer>
                <a
                    className={classNames(
                        'anchor',
                        'rh-my-0',
                        'weight-regular',
                        {
                            'rh-display-block rh-text-s rh-py-0_875': variant === VARIANTS.SIDEBAR,
                            'rh-text-m': variant !== VARIANTS.SIDEBAR,
                            'rh-fg-coconut': variant === VARIANTS.LIGHT,
                            'rh-fg-blackberry': variant !== VARIANTS.LIGHT,
                        },
                        className,
                    )}
                    href={url}
                    {...otherProps}
                >
                    <span className="anchor-text">
                        {title}
                    </span>
                </a>
            </AnchorContainer>

            <If condition={hasImage && imgPosition === IMAGE_POSITIONS.RIGHT}>
                <LazyLoadImage
                    className="image rh-text-2xs"
                    src={imgSrc}
                    alt={imgAlt}
                    width={imgWidth}
                    height={imgHeight}
                />
            </If>
        </Container>
    );
}

const AnchorWrapperDiv = styled.div``;

LinkBlockAnchor.propTypes = {
    variant: PropTypes.oneOf(Object.values(VARIANTS)),
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    isActive: PropTypes.bool,

    imgSrc: PropTypes.string,
    imgAlt: PropTypes.string,
    imgWidth: PropTypes.number, // in pixels, no unit
    imgHeight: PropTypes.number, // in pixels, no unit
    imgPosition: PropTypes.oneOf(Object.values(IMAGE_POSITIONS)),

    className: PropTypes.string,
};

const MENU_PADDING = Sizes.SPACING.ONE_AND_A_QUARTER;

const Container = styled.li`
    list-style: none;
    line-height: ${Sizes.LINE_HEIGHT.M};

    > .image {
        flex-basis: 3.75rem;
        width: 3.75rem;
        height: 3.75rem;

        border-radius: 2px;

        /* optically align top of anchor text with top of image. */
        margin-top: 6px;
    }

    > .anchor,
    > * > .anchor {
        outline: none;
        text-decoration: none;
        line-height: inherit;

        > .anchor-text {
            box-shadow: inset 0 -2px ${Colours.TRANSPARENT};
            transition: color 0.3s, box-shadow 0.3s;
        }

        &:hover,
        &:focus {
            > .anchor-text {
                color: ${Colours.BLUEBERRY};
                box-shadow: inset 0 -2px ${Colours.BLUEBERRY};
            }
        }

        &:active {
            > .anchor-text {
                color: ${Colours.BLUEBERRY_DARK};
                box-shadow: inset 0 -2px ${Colours.BLUEBERRY_DARK};
            }
        }
    }

    /* .isFlex uses block hover on Container. */
    &.isFlex {
        display: flex;
        gap: ${Sizes.SPACING.ONE};
        justify-content: space-between;

        &:hover,
        &:focus {
            cursor: pointer;

            > .anchor,
            > * > .anchor {
                > .anchor-text {
                    color: ${Colours.BLUEBERRY};
                    box-shadow: inset 0 -2px ${Colours.BLUEBERRY};
                }
            }
        }
        &:active {
            > .anchor,
            > * > .anchor {
                > .anchor-text {
                    color: ${Colours.BLUEBERRY_DARK};
                    box-shadow: inset 0 -2px ${Colours.BLUEBERRY_DARK};
                }
            }
        }
    }

    &.variant-sidebar {
        padding-left: ${MENU_PADDING};
        padding-right: ${MENU_PADDING};

        line-height: ${Sizes.LINE_HEIGHT.S};

        &.isActive {
            /* Blue "you-are-here" indicator */
            background: linear-gradient(
                to right,
                ${Colours.BLUEBERRY} 0px,
                ${Colours.BLUEBERRY} 5px,
                transparent 5px,
                transparent
            );

            > .anchor,
            > * > .anchor {
                font-weight: ${Typography.WEIGHTS.MEDIUM};
            }
        }
    }
`;

LinkBlockAnchor.blockKey = 'rh/link-block-anchor';

export default LinkBlockAnchor;
export { MENU_PADDING };
