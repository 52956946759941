import { Routes } from '@ratehub/base-ui';


export default {
    mortgage: {
        id: 'mortgage',
        route: `${Routes.MY_QUOTE_ROUTE}/mortgage`,
    },
    autoInsurance: {
        id: 'auto-insurance',
        route: `${Routes.MY_QUOTE_ROUTE}/auto-insurance`,
    },
    homeInsurance: {
        id: 'home-insurance',
        route: `${Routes.MY_QUOTE_ROUTE}/home-insurance`,
    },
    creditCard: {
        id: 'credit-card',
        route: `${Routes.MY_QUOTE_ROUTE}/credit-card`,
    },
    profileDetails: {
        id: 'profileDetails',
        route: Routes.PROFILE_DETAILS_ROUTE,
    },
    referrals: {
        id: 'referrals',
        route: Routes.REFERRALS_ROUTE,
    },
};
