import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';

import Colours from '../definitions/Colours';
import GIFT_OFFER_BANNER_COLOURS from '../definitions/GiftOfferBannerColours';
import IconGift from '../components/icons/IconGift';



function CardBanner({ text, className, borderRadius }) {
    const bannerColours = GIFT_OFFER_BANNER_COLOURS[0]; // subsequently cleaned-up by ccdep-4071

    // must shrink inner border radius to avoid unsightly gaps
    const innerBorderRadius = borderRadius?.slice(-2) === 'px'
        ? (parseInt(borderRadius) - 1).toString() + 'px'
        : borderRadius; // oops, can’t help you right now

    return (
        <Container
            borderRadius={innerBorderRadius}
            className={classNames(className, 'rh-m-0 rh-px-2', bannerColours.background)}
            data-test-name="gift-card-banner"
        >
            <span
                className={
                    `banner-text rh-display-flex rh-align-items-center rh-justify-content-center rh-text-align-center
                     rh-text-xs rh-py-0_25 rh-mx-auto rh-my-0`
                }
            >
                <IconGift
                    className="the-icon rh-display-inline-block rh-icon-xs rh-mr-0_25 rh-flex-shrink-0"
                    outlineWidth="0"
                    stroke={Colours.GRAPE_DARK}
                    fill={bannerColours.iconFill}
                />

                <span className={bannerColours.textColour}>
                    {text}
                </span>
            </span>
        </Container>
    );
}

CardBanner.propTypes = {
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
    borderRadius: PropTypes.string,
};

CardBanner.defaultProps = {
    className: undefined,
    borderRadius: '4px',
};

const Container = styled.div`
    // useful for matching the border-radius of a parent component
    border-radius: ${props => props.borderRadius} ${props => props.borderRadius} 0 0;

    > .banner-text {
        max-width: 85%;
    }
`;

export default CardBanner;
