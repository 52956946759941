import React from 'react';
import PropTypes from 'prop-types';

import { ImageSizes, Colours } from '@ratehub/base-ui';

import ImageCollectionPropType from '../definitions/ImageCollectionPropType';
import LINK_TARGETS from '../definitions/LinkTargets';
import BLOCK_ANIMATIONS from '../definitions/BlockAnimations';
import withLink from './withLink';
import VisualElement from './VisualElement';


function InlineImage({
    imageCollection,
    imageSize = ImageSizes.FULL,
    alt,
    href,
    hrefLabel,
    target = LINK_TARGETS.CURRENT_TAB,
    isLazy = true,
    borderRadius,
    borderWidth,
    borderColour,
    customWidth,
    customHeight,
    dataName,
    ...otherProps
}) {
    const isLink = href && target;

    const Component = isLink
        ? withLink(VisualElement)
        : VisualElement;

    const LinkProps = isLink
        ? {
            href: href,
            hrefLabel: hrefLabel,
            target: target,
        }
        : {};

    return (
        <Component
            className="visualElement"
            imageCollection={imageCollection
                ? extractImageCollection(imageCollection, imageSize)
                : undefined}
            alt={alt}
            isLazy={isLazy}
            borderRadius={borderRadius}
            borderWidth={borderWidth}
            borderColour={borderColour}
            restrictImageWidth
            customWidth={customWidth}
            customHeight={customHeight}
            dataName={dataName}

            {...LinkProps}
            {...otherProps}
        />
    );
}

InlineImage.propTypes = {
    imageCollection: ImageCollectionPropType.isRequired,
    imageSize: PropTypes.oneOf(Object.values(ImageSizes)),
    alt: PropTypes.string,
    href: PropTypes.string,
    hrefLabel: PropTypes.string,
    target: PropTypes.oneOf(Object.values(LINK_TARGETS)),
    isLazy: PropTypes.bool,
    borderRadius: PropTypes.string, // Tentative values: 0, 8px, 50%
    borderWidth: PropTypes.string, // Tentative values: 0 2px
    borderColour: PropTypes.oneOf(Object.values(Colours)), // Any allowed in theory
    customWidth: PropTypes.string,
    customHeight: PropTypes.string,
    dataName: PropTypes.string,
};

function extractImageCollection(imageCollection, imageSize) {
    const chosenSize = imageCollection.sizes[imageSize];

    return {
        sizes: [
            {
                url: chosenSize.url,
                height: chosenSize.height,
                width: chosenSize.width,
            },
        ],
        fallback: chosenSize.url,
        height: chosenSize.height,
        width: chosenSize.width,
        mime: imageCollection.mime,
        // no alt - use the value from the block ALWAYS
    };
}

InlineImage.blockKey = 'rh/inline-image';
InlineImage.animations = [ BLOCK_ANIMATIONS.SLIDE_AND_FADE ];
InlineImage.hasSpacingOptions = true;

export default InlineImage;
