import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { ANALYTICS_KEY, ANALYTICS_ATTRIBUTE_NAME } from '../definitions/Analytics';
import Colours from '../definitions/Colours';
import Products from '../definitions/Products';
import Sizes from '../definitions/Sizes';
import ImageSizesByType from '../definitions/ImageSizesByType';
import formatPercentage from '../functions/formatPercentage';
import getOfferTypeAsStringFromSlug from '../functions/getOfferTypeAsStringFromSlug';
import ProviderDetails from './ProviderDetails';
import PrimaryButton from './PrimaryButton';
import CardBanner from './CardBanner';


const VARIANTS = {
    ONE_TITLE: 'one-title',
    TWO_TITLE: 'two-title', // Note: this variant is experimental is is currently only meant to be enabled via the CMS
};

// despite the name, this is _not_ related to ProductCardLayout and its collection of components
function ProductCardBrief({
    className,
    CTAComponentWithProps,
    applyText,
    imageSrc,
    imageAlt,
    productType,
    cardTitle,
    productName,
    description,
    isSponsored = false,
    sponsoredText,
    isARatehubCompany = false,
    providerName,
    rate,
    hasPromo = false,
    analyticsObject,
    hasMinWidth = true,
    hasMaxWidth = true,
    isCompact = false,
    variant = VARIANTS.ONE_TITLE,

    ...otherProps
}) {
    const intl = useIntl();

    const [
        CTAComponent,
        propsForCTAComponent,
    ] = CTAComponentWithProps;

    const giftCardOffer = propsForCTAComponent?.giftCardOffer;

    const isCreditCard = productType === Products.CREDIT_CARDS;

    const cardBannerText = getCardBannerText(hasPromo, giftCardOffer, intl);
    const cardLabelText = getCardLabelText({ isSponsored, sponsoredText, hasPromo, intl });
    const shouldShowBanner = !!cardBannerText;

    const productPercentage = formatPercentage(typeof rate === 'object' ? rate.value : rate, intl);
    const primaryButtonProps = productType === Products.MORTGAGES ? {} : { forwardedAs: 'div' };

    const dataNamePrefix = `${productType}.featured`;

    // Returns either an array of list items OR the original description text.
    const cardDescription = getListFromDescription(description);

    return (
        <ProductCardItem
            ref={self => self && (self[ANALYTICS_KEY] = analyticsObject)}
            className={classNames(className, 'rh-box-sizing-border-box rh-width-100p rh-bg-coconut rh-border-radius-7px', {
                [ANALYTICS_ATTRIBUTE_NAME]: !!analyticsObject,
                'variant-two-title': variant === VARIANTS.TWO_TITLE,
            })}
            size="large"
            shouldLowerCaseMessage={isSponsored || !giftCardOffer}
            hasMinWidth={hasMinWidth}
            hasMaxWidth={hasMaxWidth}
            isCompact={isCompact}
            {...otherProps}
        >
            <CTAComponent
                {...propsForCTAComponent}
                data-name={`${dataNamePrefix}.card`}
                className={classNames(
                    'cta-target rh-display-grid rh-box-sizing-border-box rh-height-100p rh-cursor-pointer rh-text-decoration-none',
                    'rh-border-width-1px rh-border-style-solid rh-border-radius-7px rh-outline-none',
                    'rh-border-color-stone',
                    propsForCTAComponent?.className,
                    {
                        'rh-pt-0 rh-px-1 rh-pb-1': isCompact,
                        'rh-pt-0 rh-px-1_75 rh-pb-1_75': !isCompact,

                        'hover--rh-border-color-blueberry-dark active--rh-border-color-blueberry-darkest': !shouldShowBanner,
                        'hover--rh-border-color-grape active--rh-border-color-grape-darkest': shouldShowBanner,
                    },
                )}
            >
                <If condition={variant === VARIANTS.ONE_TITLE}>
                    {/* Only show ONE of EITHER card banner or card label. */}
                    {/* CARD BANNER */}
                    <If condition={shouldShowBanner}>
                        <CardBanner
                            className="banner"
                            text={cardBannerText}
                            borderRadius="7px"
                        />
                    </If>

                    {/* CARD LABEL */}
                    <If condition={!shouldShowBanner}>
                        <p className="label rh-display-flex rh-text-s rh-my-0 leading-xs rh-fg-blackberry rh-text-lowercase">
                            {cardLabelText}
                        </p>
                    </If>
                </If>

                {/* PROVIDER DETAILS OR CARD TITLE */}
                <Choose>
                    {/* 'rate' type is inconsistent and has to support empty string and string '0' from CMS */}
                    <When condition={rate || rate == 0}>
                        <ProviderDetails
                            isRatehubCompany={isARatehubCompany}
                            providerName={providerName}
                            providerIcon={imageSrc}
                            className={classNames(
                                'provider-details rh-display-flex rh-px-0',
                                isCompact ? 'rh-pb-1': 'rh-pb-2',
                            )}
                            alwaysShowReviews
                            isLogoWrapped={false}
                        />
                    </When>
                    <Otherwise>
                        <p className={classNames('card-title rh-text-align-center rh-title-s  rh-pb-1 rh-my-0 rh-fg-blackberry', {
                            'rh-pt-0': variant === VARIANTS.ONE_TITLE,
                            'rh-pt-2': variant === VARIANTS.TWO_TITLE })}
                        >
                            {cardTitle}
                        </p>
                    </Otherwise>
                </Choose>

                {/* PRODUCT CONTENT */}
                <div className="product-content rh-display-flex rh-align-items-center rh-fg-blackberry">
                    <Choose>
                        <When condition={rate == null}>
                            <LazyLoadImage
                                className="large-badge rh-display-block rh-my-0_5 rh-width-auto"
                                src={imageSrc}
                                alt={getAltText(isCreditCard, imageAlt, intl)}
                                width={
                                    isCreditCard
                                        ? ImageSizesByType.CREDIT_CARDS.CARD_FEATURED_PRODUCT.WIDTH
                                        : ImageSizesByType.PROVIDERS.BADGE_MEDIUM.WIDTH
                                }
                                height={
                                    isCreditCard
                                        ? ImageSizesByType.CREDIT_CARDS.CARD_FEATURED_PRODUCT.HEIGHT
                                        : ImageSizesByType.PROVIDERS.BADGE_MEDIUM.HEIGHT
                                }
                            />
                        </When>

                        <Otherwise>
                            <span className="rh-title-3xl rh-my-0 rate leading-xs">
                                {/* Mortgage products receive whole rate object */}
                                {productPercentage}
                            </span>
                        </Otherwise>
                    </Choose>

                    {/* PRODUCT TITLE */}
                    <If condition={variant === VARIANTS.TWO_TITLE && productName}>
                        <p className="rh-text-align-center rh-title-s rh-py-0 rh-my-0 rh-fg-blackberry">
                            {productName}
                        </p>
                    </If>

                    <If condition={description}>
                        <Choose>
                            <When condition={Array.isArray(cardDescription)}>
                                <ul className="description rh-text-s rh-text-align-left leading-s rh-pl-0 rh-mx-2 rh-my-1">
                                    <For
                                        each="listItem"
                                        of={cardDescription}
                                    >
                                        <li
                                            key={listItem}
                                        >
                                            {listItem}
                                        </li>
                                    </For>
                                </ul>
                            </When>
                            <Otherwise>
                                <p className="description rh-text-s rh-text-align-center leading-s rh-my-1">
                                    {cardDescription}
                                </p>
                            </Otherwise>
                        </Choose>
                    </If>
                </div>

                {/* CALL-TO-ACTION BUTTON */}
                {/* credit cards will provide its own PrimaryButton */}
                <If condition={productType !== Products.CREDIT_CARDS}>
                    <PrimaryButton
                        {...primaryButtonProps}
                        data-name={`${dataNamePrefix}.apply.cta`}
                        className={classNames(
                            'cta rh-text-lowercase rh-cursor-pointer',
                            {
                                'rh-display-flex': primaryButtonProps?.forwardedAs === 'div',
                            },
                        )}
                    >
                        {applyText}
                    </PrimaryButton>
                </If>
            </CTAComponent>
        </ProductCardItem>
    );
}

ProductCardBrief.propTypes = {
    applyText: PropTypes.string,    // typically required, but it is optional for credit cards
    CTAComponentWithProps: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.node,
            PropTypes.func,
            PropTypes.shape({
                className: PropTypes.string,
                onClick: PropTypes.func,
                cardInfo: PropTypes.object, // CreditCardSynopsisShape from '@ratehub/cc-common'
                buttonSize: PropTypes.string,
                href: PropTypes.string,
                target: PropTypes.string,
                rel: PropTypes.string,
            }),
        ]),
    ).isRequired,
    imageSrc: PropTypes.string.isRequired,
    imageAlt: PropTypes.string.isRequired,
    productType: PropTypes.oneOf([ ...Object.values(Products) ]).isRequired,
    cardTitle: PropTypes.string.isRequired,
    productName: PropTypes.string,
    bannerText: PropTypes.string,
    className: PropTypes.string,
    description: PropTypes.string,
    hasPromo: PropTypes.bool,
    isARatehubCompany: PropTypes.bool,
    isSponsored: PropTypes.bool,
    sponsoredText: PropTypes.string,
    analyticsObject: PropTypes.object,
    providerName: PropTypes.string,
    hasMinWidth: PropTypes.bool,
    hasMaxWidth: PropTypes.bool,
    isCompact: PropTypes.bool,
    variant: PropTypes.oneOf(Object.values(VARIANTS)),
    rate: PropTypes.oneOfType([ PropTypes.number, PropTypes.string, PropTypes.object ]),
};


const MESSAGES = defineMessages({
    RATEHUB_COMPANY_BYLINE: {
        id: 'FeaturedProduct.ratehubCompanyByline',
        defaultMessage: 'A Ratehub Company',
    },
    FEATURED: {
        id: 'FeaturedProduct.banner.featured',
        defaultMessage: 'Featured',
    },
    PRODUCT_ALT_TEXT_CARD: {
        id: 'FeaturedProduct.product.altTextCard',
        defaultMessage: '{productDescription}',
    },
    PRODUCT_ALT_TEXT_LOGO: {
        id: 'FeaturedProduct.product.altTextLogo',
        defaultMessage: '{productDescription} logo',
    },
    SPONSORED: {
        id: 'FeaturedProduct.banner.sponsored',
        defaultMessage: 'Sponsored',
    },
    PROMO_OFFER: {
        id: 'FeaturedProduct.banner.promoOffer',
        defaultMessage: 'Limited promo offer',
    },
    GIFT_CARD_OFFER: {
        id: 'FeaturedProduct.edb.cc.ribbon-message.gift-card-offer',
        defaultMessage: '{offerType} offer',
    },
});

function getCardBannerText(hasPromo, giftCardOffer, intl) {
    return hasPromo && giftCardOffer
        ? intl.formatMessage(MESSAGES.GIFT_CARD_OFFER, {
            // offerType expression is a copy of getCardOfferTypeAsString(), but that lives in cc-common
            // once the transition is complete, it will always be a slug and this expression simplifies
            offerType: giftCardOffer.offerTypeName
                ? giftCardOffer.offerTypeName
                : getOfferTypeAsStringFromSlug(giftCardOffer.offerType, intl),
        })
        : undefined;
}

function getCardLabelText({ isSponsored, sponsoredText, hasPromo, intl }) {
    return isSponsored
        ? sponsoredText || intl.formatMessage(MESSAGES.SPONSORED) // SPONSORED always has priority over PROMO_OFFER / FEATURED
        : intl.formatMessage(hasPromo ? MESSAGES.PROMO_OFFER : MESSAGES.FEATURED);
}

function getAltText(productType, imageAlt, intl) {
    return intl.formatMessage(productType === Products.CREDIT_CARDS
        ? MESSAGES.PRODUCT_ALT_TEXT_CARD
        : MESSAGES.PRODUCT_ALT_TEXT_LOGO, {
        productDescription: imageAlt,
    });
}

/**
 * If description is a multi-line string where each line starts with a hypen,
 * return an array of list items (minus hyphens). Otherwise, just return
 * the original, untouched description.
 * @param description string
 */
function getListFromDescription(description) {
    // We only want to parse as a list if it's a string containing newlines.
    if (typeof description !== 'string' || !description.includes('\n')) {
        return description;
    }

    return description.split('\n').map(listItem => {
        return listItem.replace(/^ *-? */, '');
    });
}


const ProductCardItem = styled.div`
    ${props => props.hasMinWidth && `
        min-width: 19em;
    `}
    ${props => props.hasMaxWidth && `
        max-width: 22em;
    `}

    /* Typically set in FeaturedProductGroup but also here in case we ever want
    to render a FeaturedProduct on it's own. Please use as="div" at the very
    least if you go that route though. */
    list-style: none;

    &:last-child {
        margin-right: 0;
    }

    > .cta-target {
        /* Grid notes: Each card's grid context takes up the full height of each
        card and most grid rows have a height (and many are auto) so grid lines
        aren't guaranteed to line up card-to-card. There may be leftover space
        in a given grid though, so assigning 1fr to the product content tells
        that section to "pick up the leftover slack" and thereby ensures buttons
        get aligned to the bottom of the card. */

        /* [ banner | label ] | cardTitle | product content | button */
        grid-template-rows: ${Sizes.SPACING.FOUR} auto 1fr auto;

        > .banner {
            align-self: self-start;

            margin-left: ${props => props.isCompact ? `-${Sizes.SPACING.ONE}` : `-${Sizes.SPACING.ONE_AND_THREE_QUARTERS}`};
            margin-right: ${props => props.isCompact ? `-${Sizes.SPACING.ONE}` : `-${Sizes.SPACING.ONE_AND_THREE_QUARTERS}`};
        }

        > .label {
            grid-row: 1;
            align-self: center;

            align-items: center;
            justify-content: center;

            &::before,
            &::after {
                content: '';
                display: inline-block;

                width: ${Sizes.SPACING.QUARTER};
                height: 1px;

                background-color: ${Colours.BLACKBERRY};
            }

            &::before {
                margin-right: ${Sizes.SPACING.HALF};
            }

            &::after {
                margin-left: ${Sizes.SPACING.HALF};
            }
        }

        > .card-title {
            grid-row: 2;
            align-self: center;
        }

        > .provider-details {
            flex: 1;
            justify-content: center;
            grid-row: 2;

            min-height: 4.125rem;
        }

        > .product-content {
            grid-row: 3;

            flex-direction: column;
            row-gap: ${Sizes.SPACING.QUARTER};

            // establish a min-height for times when no cards in the carousel
            // have a description so the card image/rate isn't squished up
            // against the button.
            min-height: ${Sizes.SPACING.SEVEN};

            > .large-badge {
                min-width: 6rem;
            }

            > .description {
                min-height: 3.5rem; // reserves space for max 3 lines of text

                :is(ul) {
                    list-style-type: disc;

                    li {
                        ::marker {
                            color: ${Colours.BLUEBERRY_DARK};
                        }
                    }
                }
            }
        }

        > .cta {
            /* REQUIREMENTS:
                - all buttons should be horizontally aligned with one another
                - buttons should be aligned to very bottom of card */
            grid-row: 4 / 5;
            align-self: end;

            &:is(div) {
                align-items: center;
                justify-content: center;
            }
        }
    }

    /* Probably want to re-organize how grid styles get applied so classic and new
    grid styles are each in their own neat sections if this experiment wins but
    keeping these seprate for now to make it easy to remove if the experiment loses. */
    &.variant-two-title > .cta-target {
        /* card title | product content (contains product title) | button(s) */

        > .card-title {
            grid-row: 1;
        }

        > .product-content {
            grid-row: 2;
        }

        > .cta {
            grid-row: 3;
        }
    }
`;


export default ProductCardBrief;
export { VARIANTS };
