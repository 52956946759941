import { PROVINCE, Locales, getLanguageFromLocale, noticeError } from '@ratehub/base-ui';

import { Products } from '../definitions/Products';
import enrichCreditCardProducts from './enrichCreditCardProducts';
import enrichMortgageProducts from './enrichMortgageProducts';
import enrichGICProducts from './enrichGICProducts';
import enrichSavingsProducts from './enrichSavingsProducts';
import enrichRoboProducts from './enrichRoboProducts';
import enrichChequingProducts from './enrichChequingProducts';
import enrichCryptoProducts from './enrichCryptoProducts';
import enrichLoanProducts from './enrichLoanProducts';


const DEPRECATED_YUKON_PROVINCE_CODE = 'YK';

/* Reassembles products in a flat array */
function flattenProductGroups(groups) {
    return groups
        .map(group => [].concat(group.products))
        .reduce((acc, val) => acc.concat(val), []);
}

/**
 * Enrich all product groups with additional data relevant to that product type
 * @param {object[]} productGroups
 * @param {object} options
 * @param {string} [options.province='ON'] - province code
 * @param {string} [options.locale='en-CA'] - Locale string
 * @param {string} [options.hideCTAIfNotMonetized=false] - whether to hide the CTA button if CC product is not monetized
 */
export default async function enrichAllProductGroups(
    productGroups,
    {
        province = PROVINCE.ONTARIO,
        locale = Locales.ENGLISH,
        hideCTAIfNotMonetized = false,
    } = {},
) {
    const creditCards = productGroups?.filter(group => group.type === Products.CREDIT_CARDS);
    const mortgages = productGroups?.filter(group => group.type === Products.MORTGAGES);
    const gics = productGroups?.filter(group => group.type === Products.GICS);
    const savings = productGroups?.filter(group => group.type === Products.SAVINGS);
    const chequing = productGroups?.filter(group => group.type === Products.CHEQUING);
    const robo = productGroups?.filter(group => group.type === Products.ROBO_ADVISORS);
    const crypto = productGroups?.filter(group => group.type === Products.CRYPTO);
    const loans = productGroups?.filter(group => group.type === Products.LOANS);

    const config = {
        language: getLanguageFromLocale(locale),
        province,
        hideCTAIfNotMonetized,
    };

    // Handle conversion of Yukon province code
    if (config.province === PROVINCE.YUKON) {
        config.province = DEPRECATED_YUKON_PROVINCE_CODE;
    }

    try {
        // NOTE: Nothing is returned here since these sub-functions are mutating productGroups
        await Promise.all([
            creditCards.length
                ? enrichCreditCardProducts(flattenProductGroups(creditCards), config)
                : null,
            mortgages.length
                ? enrichMortgageProducts(
                    flattenProductGroups(mortgages),
                    config,
                )
                : null,
            gics.length
                ? enrichGICProducts(
                    flattenProductGroups(gics),
                    config,
                )
                : null,
            savings.length
                ? enrichSavingsProducts(
                    flattenProductGroups(savings),
                    config,
                )
                : null,
            chequing.length
                ? enrichChequingProducts(
                    flattenProductGroups(chequing),
                    config,
                )
                : null,
            robo.length
                ? enrichRoboProducts(
                    flattenProductGroups(robo),
                    config,
                )
                : null,
            crypto.length
                ? enrichCryptoProducts(
                    flattenProductGroups(crypto),
                    config,
                )
                : null,
            loans.length
                ? enrichLoanProducts(
                    flattenProductGroups(loans),
                    config,
                )
                : null,
        ]);
    } catch (e) {
        // Noting error here since Promise.all would throw with the first
        //  rejection and this can be used to keep track of those errors.
        noticeError(e, { message: '[enrichAllProductGroups] error within Promise.all enrichment', ...config });
        throw e;
    }
}
