import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

import {
    PrimaryAnchor,
    AlternateAnchor,
    MessagePropType,
} from '@ratehub/base-ui';

import HeadingPropTypes from '../definitions/HeadingPropTypes';
import FactBlock, { FactBlockType, COLUMN_SIZES } from './FactBlock';
import VisualElement from './VisualElement';
import HeadingBlock from './HeadingBlock';


const VARIANTS = {
    LIGHT: 'light',
    DARK: 'dark',
};

function FactBlockContainer({
    heading,
    ctaHref,
    ctaText,
    children,
    useAd = false,
    variant = VARIANTS.DARK,
    type = FactBlockType.DEFAULT,
    columnSize = COLUMN_SIZES.LARGE,
    className,
    ...otherProps
}) {
    const Anchor = type === FactBlockType.DEFAULT ? PrimaryAnchor : AlternateAnchor;
    const isCardType = type === FactBlockType.CARD;

    const fakeListItemClasses = classNames(
        {
            'rh-ml-3': !isCardType,
            'rh-ml-2': isCardType,
        },
        'list-item rh-mb-0 rh-list-style-type-none'
    );

    return (
        <Container
            type={type}
            className={classNames('rh-m-auto', className)}
            {...otherProps}
        >
            <HeadingBlock
                className="rh-mb-4"
                variant={variant}
                isCentered={isCardType}
                {...heading}
            />

            <ul className="list rh-display-flex rh-justify-content-flex-start rh-flex-wrap rh-p-0">
                {children}

                <If condition={useAd}>
                    <FactBlock.ListItem
                        className={classNames({
                            'rh-ml-3': !isCardType,
                            'rh-ml-1': isCardType,
                        }, 'list-item rh-mb-0 rh-list-style-type-none')}
                        type={type}
                        columnSize={columnSize}
                    >
                        <VisualElement
                            isAd={useAd}
                            className="item-with-ad rh-m-auto rh-display-flex rh-height-100p rh-flex-direction-column"
                        />
                    </FactBlock.ListItem>
                </If>

                {/*
                    Where n is the maximum number of blocks that can fit in a row, we need
                    at least n-1 placeholders here to prevent blocks filling more of the space
                    than they should. (In the case of a row with 1 block, we need n-1 placeholders
                    to fill the rest of the row).

                    We currently show this component in places where n may be up to 4 so
                    including 3 of these, which provides enough placeholders for all usages.
                    Not ideal, would like to refactor this to not need placeholders at all.
                */}

                <FactBlock.ListItem
                    className={fakeListItemClasses}
                    type={type}
                    columnSize={columnSize}
                    aria-hidden="true"
                />
                <FactBlock.ListItem
                    className={fakeListItemClasses}
                    type={type}
                    columnSize={columnSize}
                    aria-hidden="true"
                />
                <FactBlock.ListItem
                    className={fakeListItemClasses}
                    type={type}
                    columnSize={columnSize}
                    aria-hidden="true"
                />
            </ul>

            <If condition={ctaHref}>
                <div className={classNames({
                    'rh-mt-3': isCardType,
                    'rh-mt-0': !isCardType,
                }, 'cta-container rh-mx-auto')}
                >
                    <Anchor
                        data-name="factBlockContainer-cta"
                        href={ctaHref}
                        message={ctaText}
                    />
                </div>
            </If>
        </Container>
    );
}

FactBlockContainer.propTypes = {
    heading: HeadingPropTypes,
    ctaHref: PropTypes.string,
    ctaText: MessagePropType,
    variant: PropTypes.oneOf(Object.values(VARIANTS)),
    children: PropTypes.any.isRequired,
    useAd: PropTypes.bool,
    type: PropTypes.oneOf(Object.values(FactBlockType)),
    columnSize: PropTypes.oneOf(Object.values(COLUMN_SIZES)),
    className: PropTypes.string,
};

const Container = styled.div`
    margin: auto;

    > .list {
        // Have to use this approach because grid-gap won't work with our "fake" flex items in place
        margin: 0px 0px 0px -3rem;

        .list-item {
            min-height: 1px;
        }

        .item-with-ad {
            max-width: 29.688em;
        }
    }

    > .cta-container {
        max-width: 24rem;
    }
`;

FactBlockContainer.blockKey = 'rh/fact-block-container';
FactBlockContainer.hasHeading = true;
FactBlockContainer.requiresLayoutRow = true;

export default FactBlockContainer;
