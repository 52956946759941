import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import HeadingPropTypes from '../definitions/HeadingPropTypes';
import HeadingBlock from './HeadingBlock';
import AccordionItem from './AccordionItem';


const VARIANTS = {
    LIGHT: 'light',
    DARK: 'dark',
};
function FaqAccordion({
    heading,
    variant = VARIANTS.DARK,
    questions = [],
    alwaysRenderChildMarkup = true,
    className,
}) {
    const schema = {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        'mainEntity': questions.map(question => ({
            '@type': 'Question',
            'name': question.title,
            'acceptedAnswer': {
                '@type': 'Answer',
                'text': question.content,
            },
        })),
    };

    return (
        /* Matching rich text block - will be removed when we implement proper block width sizing */
        <div className={classNames(
            'rh-mx-auto rh-max-width-800px',
            className,
        )}
        >
            <script
                type="application/ld+json"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify(schema, null, 4),
                }}
            />

            <HeadingBlock
                {...heading}
                variant={variant}
            />

            <For
                each="question"
                of={questions}
            >
                <AccordionItem
                    key={question.title}
                    alwaysRenderChildMarkup={alwaysRenderChildMarkup}
                    {...question}
                />
            </For>
        </div>
    );
}

FaqAccordion.propTypes = {
    heading: HeadingPropTypes,
    questions: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
    })),
    variant: PropTypes.oneOf(Object.values(VARIANTS)),
    alwaysRenderChildMarkup: PropTypes.bool,
    className: PropTypes.string,
};

FaqAccordion.hasHeading = true;
FaqAccordion.blockKey = 'rh/faq-accordion';
FaqAccordion.requiresLayoutRow = true;

export default FaqAccordion;
