import React from 'react';
import styled from 'styled-components';
import { withSize } from 'react-sizeme';
import PropTypes from 'prop-types';

import { ImageSizes } from '@ratehub/base-ui';

import BlogPostPropType from '../definitions/BlogPostPropType';
import Carousel from './Carousel';
import BlogSpotlightPost from './BlogSpotlightPost';


const GAP_WIDTH = '2rem';
const MIN_POST_WIDTH = 255;

function BlogSpotlightPostList({
    posts = [],
    isDisabled = false,
    titleAs = 'h4',
    size,
    ...otherProps
}) {
    const containerWidth = size?.width;

    const isInitialized = !!containerWidth;

    const potentialColumns = !isInitialized
        ? posts.length
        : Math.max(Math.floor(containerWidth / MIN_POST_WIDTH), 1); // Minimum of 1

    const visibleColumns = Math.max(Math.min(potentialColumns, posts.length), 1);

    const hasMinWidth = visibleColumns === 1;
    const hasMaxWidth = visibleColumns === 1 || potentialColumns > posts.length;

    const carouselProps = {
        hideDots: true,
        overflow: 'visible',
        gap: GAP_WIDTH,
    };

    return (
        <Container
            visibleColumns={visibleColumns}
        >
            <Carousel
                {...carouselProps}
                {...otherProps}
            >
                <For
                    each="post"
                    of={posts}
                    index="index"
                >
                    <BlogSpotlightPost
                        className="post"
                        key={post.title}
                        id={post.id}
                        title={post.title}
                        titleAs={titleAs}
                        url={post.url}
                        imageUrl={getFeaturedImage(post.imageCollection)}
                        imageAlt={post.imageAlt}
                        tags={post.tags}
                        isDisabled={isDisabled}
                        hasMinWidth={hasMinWidth}
                        hasMaxWidth={hasMaxWidth}
                    />
                </For>
            </Carousel>
        </Container>
    );
}

BlogSpotlightPostList.propTypes = {
    size: PropTypes.object.isRequired,
    posts: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape(BlogPostPropType)),
        PropTypes.objectOf(PropTypes.shape(BlogPostPropType)), // mobx observable array
    ]),
    isDisabled: PropTypes.bool,
    titleAs: PropTypes.oneOf([ 'h2', 'h3', 'h4' ]),
};

function getFeaturedImage(imageCollection) {
    const sizes = imageCollection?.sizes;

    return sizes
        ? (sizes?.[ImageSizes.S]?.url || sizes?.[ImageSizes.M]?.url || sizes?.[ImageSizes.FULL]?.url)
        : undefined;
}

const Container = styled.div`
    ${props => props.visibleColumns > 1 && `
        .post {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: calc((100% / ${props.visibleColumns}) - ((${props.visibleColumns - 1} / ${props.visibleColumns}) * ${GAP_WIDTH}));
        }
    `}
`;

export default withSize()(BlogSpotlightPostList);
