import React from 'react';
import PropTypes from 'prop-types';
import groupBy from 'lodash.groupby';
import classNames from 'classnames';

import DateCategorizedRow from './DateCategorizedRow';


function DateCategorizedTable({
    items,
    showLinkArrow = false,
    ...otherProps
}) {
    const categorizedItems = groupItemsByYear(items);
    /*  Need to sort the keys since by default
        they go in asc order since they're Numbers */
    const sortedKeys = Object.keys(categorizedItems).sort().reverse();

    return (
        <div
            role="table"
            {...otherProps}
        >
            <For
                each="group"
                of={sortedKeys}
            >
                <div role="row">
                    <h3
                        className={classNames('rh-title-m', 'rh-mt-4', 'rh-mb-0')}
                        /* eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role */
                        role="columnheader"
                    >
                        {group}
                    </h3>
                </div>

                <For
                    each="item"
                    of={categorizedItems[group]}
                >
                    <DateCategorizedRow
                        key={item.bodyText}
                        date={item.date}
                        title={item.title}
                        bodyText={item.bodyText}
                        href={item.href}
                        showLinkArrow={showLinkArrow}
                    />
                </For>
            </For>
        </div>
    );
}

function groupItemsByYear(items) {

    return groupBy(items, (item) => {
        const date = new Date(item.date);
        return date.getUTCFullYear();
    });
}

DateCategorizedTable.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.object,
    ).isRequired,
    showLinkArrow: PropTypes.bool,
};

export default DateCategorizedTable;
