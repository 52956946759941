import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { defineMessages } from 'react-intl';
import classNames from 'classnames';

import {
    Sizes,
    Colours,
    IconChooser,
    IconMapLazy,
    Paragraph,
    AnchorWithIcon,
    IconArrowRight,
} from '@ratehub/base-ui';


function NavigationListItem({ iconKey, title, readingTime, message, href, hrefText, ...otherProps }) {
    return (
        <NavigationListItem.Grid {...otherProps}>
            <IconChooser
                className="icon rh-mr-1"
                iconKey={iconKey}
                stroke={Colours.BLUEBERRY}
                size="XL"
            />

            <MyMainContent>
                <h3 className={classNames('rh-title-m', 'rh-mr-2', 'display-inline')}>
                    {title}
                </h3>

                <If condition={readingTime}>
                    <MyReadingTime
                        message={{
                            descriptor: MESSAGES.READING_TIME,
                            values: { minutes: readingTime },
                        }}
                    />
                </If>

                <If condition={message}>
                    <Paragraph>
                        {message}
                    </Paragraph>
                </If>
            </MyMainContent>

            <MyAnchorWithIcon
                data-name="navigationListItem-learnMore"
                href={href}
                iconComponent={IconArrowRight}
                message={hrefText}
            />
        </NavigationListItem.Grid>
    );
}

NavigationListItem.propTypes = {
    iconKey: PropTypes.oneOf(Object.keys(IconMapLazy)).isRequired,
    title: PropTypes.string.isRequired,
    readingTime: PropTypes.number,
    message: PropTypes.string,
    href: PropTypes.string.isRequired,
    hrefText: PropTypes.string.isRequired,
    intl: PropTypes.object.isRequired,
};

/* STYLE CONSTS */
const GRID_GAP = Sizes.SPACING.ONE;

NavigationListItem.Grid = styled.li`
    display: -ms-grid;
    display: grid;

    -ms-grid-columns: calc(${Sizes.SPACING.FOUR} + 3%) 1fr;
    grid-template-columns: calc(${Sizes.SPACING.FOUR} + 3%) 1fr;

    -ms-grid-rows: auto ${GRID_GAP} ${Sizes.SPACING.THREE};
    grid-template-rows: auto ${Sizes.SPACING.THREE};
    grid-row-gap: ${GRID_GAP};

    margin-top: calc(${Sizes.SPACING.ONE} + 4%);
    padding-bottom: calc(${Sizes.SPACING.ONE} + 4%);
    border-bottom: 1px solid ${Colours.STONE};

    .icon {
        -ms-grid-row: 1;
        grid-row: 1;

        -ms-grid-column: 1;
        grid-column: 1/2;
        max-width: calc(100% - ${Sizes.SPACING.ONE});
    }
`;

const MyMainContent = styled.div`
    > .display-inline {
        display: inline;
    }

    -ms-grid-row: 1;
    grid-row: 1;

    -ms-grid-column: 2;
    grid-column: 2/3;

`;

const MyReadingTime = styled(Paragraph)`
    display: inline-block;
    min-width: 12rem;
    margin: 0;
`;

const MyAnchorWithIcon = styled(AnchorWithIcon)`
    -ms-grid-row: 3;
    grid-row: 2;

    -ms-grid-column: 2;
    grid-column: 2/3;
`;

const MESSAGES = defineMessages({
    READING_TIME: {
        id:'web-components.NavigationListItem.reading-time',
        defaultMessage: `{minutes, plural,
            one {# minute read}
            other {# minute read}
        }`,
    },
});

NavigationListItem.blockKey = 'rh/navigation-list-item';

export default NavigationListItem;
