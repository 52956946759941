/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import classNames from 'classnames';

import { Colours, Sizes, Typography, ZIndex, ImageSizes } from '@ratehub/base-ui';

import Placeholders from '../definitions/Placeholders';
import TeamMemberPropType from '../definitions/TeamMemberPropType';
import VisualElement from './VisualElement';


const PLACEHOLDER_IMAGE = Placeholders['300x300'];
const MAX_IMAGE_SIZE = '12.5rem';

function TeamMembersList({
    people,
    hasTopMargin = false,
    hoverText,
    ...otherProps
}) {
    return (
        <TeamMembersList.List
            hasTopMargin={hasTopMargin}
            {...otherProps}
        >
            <For
                each="person"
                of={people}
            >
                <TeamMembersList.ListItem
                    content={hoverText}
                    key={person.name}
                >
                    <Choose>
                        <When condition={person.href && hoverText}>
                            <a
                                className="person-anchor"
                                href={person.href}
                            >
                                <TeamMember
                                    {...person}
                                />
                            </a>
                        </When>
                        <Otherwise>
                            <TeamMember
                                {...person}
                            />
                        </Otherwise>
                    </Choose>

                </TeamMembersList.ListItem>
            </For>
        </TeamMembersList.List>
    );
}

// eslint-disable-next-line react/prop-types
const TeamMember = function({ name, title, imageAlt, imageCollection, imageUrl }) {
    return (
        <>
            <div
                className="image-container"
            >
                <LazyLoadComponent
                    placeholder={(
                        <img
                            src={PLACEHOLDER_IMAGE}
                            alt={imageAlt || ''}
                        />
                    )}
                >
                    <VisualElement
                        imageCollection={createImageCollection(imageCollection)}
                        imageUrl={imageUrl}
                        alt={imageAlt}
                    />
                </LazyLoadComponent>
            </div>

            <If condition={name}>
                <p
                    className={classNames('person-name', Typography.CLASSES.WEIGHT_MEDIUM, Typography.CLASSES.TEXT_L)}
                >
                    {name}
                </p>
            </If>

            <If condition={title}>
                <p
                    className={classNames('job-title', Typography.CLASSES.TEXT_M)}
                >
                    {title}
                </p>
            </If>
        </>
    );
};

TeamMembersList.propTypes = {
    people: PropTypes.arrayOf(TeamMemberPropType).isRequired,
    hasTopMargin: PropTypes.bool,
    hoverText: PropTypes.string,
};

TeamMembersList.List = styled.ul`
    /*  Using Flex for IE11 since there's no graceful grid solution */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(${MAX_IMAGE_SIZE}, 1fr));
    grid-column-gap: ${Sizes.SPACING.ONE};
    grid-row-gap: ${Sizes.SPACING.TWO};

    padding-left: 0;
    margin-top: ${props => props.hasTopMargin ? Sizes.SPACING.FOUR : 0 };
`;

TeamMembersList.ListItem = styled.li`
    /* For IE11 */
    flex: 0 0 ${MAX_IMAGE_SIZE};
    justify-self: center;

    list-style: none;
    padding: 0 ${Sizes.SPACING.ONE};

    .person-anchor {
        text-decoration: none;
        outline: none;
        color: currentColor;

        &:hover,
        &:focus {
            .image-container {
                &::before,
                &::after {
                    opacity: 1;
                }
            }

            .person-name,
            .job-title {
                color: ${Colours.BLUEBERRY};
            }
        }
    }

    .image-container {
        position: relative;

        width: ${MAX_IMAGE_SIZE};
        height: ${MAX_IMAGE_SIZE};
        margin: 0 auto;

        border: 4px solid ${Colours.COCONUT};
        border-radius: 50%;

        box-shadow: 0 2px 10px ${Colours.STONE};
        overflow: hidden;

        &::before,
        &::after {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;

            border-radius: 50%;
            transition: opacity 300ms;

            opacity: 0;

            z-index: ${ZIndex.ELEMENTS};
        }

        &::before {
            content: ' ';

            background: ${Colours.STONE_DARKEST};
            mix-blend-mode: multiply;
        }

        &::after {
            content: "${props => props.content}";
            display: flex;
            align-items: center;

            padding: ${Sizes.SPACING.ONE};

            color: white;
            font-size: ${Sizes.FONTS.S};
            font-weight: ${Typography.WEIGHTS.MEDIUM};
            text-align: center;

            width: 80%; /* this works fine for English, but I'm not sure about the official French translation - It works with '"lire la bio sur LinkedIn"' */
            margin-left: auto;
            margin-right: auto;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    .person-name {
        margin: ${Sizes.SPACING.TWO} auto ${Sizes.SPACING.QUARTER};

        text-align: center;

        transition: color 300ms;
    }

    .job-title {
        margin-top: 0;
        margin-bottom: 0;

        text-align: center;

        transition: color 300ms;
    }
`;

function createImageCollection(imageCollection) {
    const sizes = imageCollection?.sizes;

    const preparedCollection = imageCollection
        ? {
            sizes: [
                {
                    url: sizes?.[ImageSizes.S] || sizes[ImageSizes.XS],
                    breakpoint: '(min-resolution: 192dpi)',
                },
            ],
            fallback: sizes?.[ImageSizes.XS] || sizes[ImageSizes.M],
            width: sizes?.[`${ImageSizes.S}-width`] || 200,
            height: sizes?.[`${ImageSizes.S}-height`] || 200,
            alt: imageCollection.alt || imageCollection.title,
            mime: imageCollection.mime,

        }
        : undefined;

    return preparedCollection?.sizes.some(size => size.url)
        ? preparedCollection
        : undefined;
}

export default TeamMembersList;
