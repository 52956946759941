import { APPROVAL_LIKELIHOOD } from '@ratehub/documents/loans-journey-document/stable';


function getLoanShape(loan) {
    return {
        id: loan.ids.primary,
        slug: loan.slug,

        provider: {
            name: loan.provider.name,
            id: loan.provider.ids.primary,
            logo: loan.provider.logo,
        },

        isSecured: loan.secured,

        loanAmountRange:{
            loanAmount: loan.loan_amount.value ?? undefined,
            min: loan.loan_amount.min ?? undefined,
            max: loan.loan_amount.max ?? undefined,
        },
        termRange: {
            term: loan.term.value ?? undefined,
            min: loan.term.min ?? undefined,
            max: loan.term.max ?? undefined,
        },
        aprRange: {
            apr: loan.apr.value ?? undefined,
            min: loan.apr.min ?? undefined,
            max: loan.apr.max ?? undefined,
        },

        expirationDate: loan.expires_at,

        category: loan.category,

        payment: {
            frequency: loan.calculated?.payment?.frequency,
            amount: loan.calculated?.payment?.amount,
        },

        approvalLikelihood: Number.isInteger(loan.approval_likelihood)
            ? API_TO_APPROVAL_LIKELIHOOD[loan.approval_likelihood]
            : APPROVAL_LIKELIHOOD.unknown,

        summaryDetails: loan.description.summary || [],
        disclaimer: loan.description.disclaimer,

        applyURL: loan.urls.apply,
        applyRedirectURL: loan.urls.apply_redirect,

        isMonetized: loan.monetized,
        isSponsored: false, // TODO: will be implemented with https://ratehub.atlassian.net/browse/CCDEP-3945
    };
}

const API_TO_APPROVAL_LIKELIHOOD = [
    APPROVAL_LIKELIHOOD.unknown,        // 0 -> 'unknown'
    APPROVAL_LIKELIHOOD.poor,           // 1 -> 'poor'
    APPROVAL_LIKELIHOOD.fair,           // 2 -> 'fair'
    APPROVAL_LIKELIHOOD.good,           // 3 -> 'good'
    APPROVAL_LIKELIHOOD.approved,       // 4 -> 'approved'
];

export default getLoanShape;
