import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rgba } from 'polished';

import {
    Colours,
} from '@ratehub/base-ui';


function BoxOverlay({
    offsetY = 200,
    children,
    ...otherProps
}) {
    return (
        <Container
            offsetY={offsetY}
            {...otherProps}
        >
            {children}
        </Container>
    );
}

BoxOverlay.propTypes = {
    offsetY: PropTypes.number,
    children: PropTypes.any.isRequired,
};

const Container = styled.div`
    position: relative;

    max-width: 1440px;

    margin-top: ${props => `-${props.offsetY}px`};
    margin-left: auto;
    margin-right: auto;

    background-color: ${Colours.COCONUT};
    border-radius: 4px;
    box-shadow: 0px 2px 11px 0px ${rgba(Colours.STONE, 0.7)};

    // Special case for BoxOverlay
    // Override CONTENT_MAX_WIDTH at the top level or else elements are not wide enough
    > * > .flex-content {
        max-width: none;
    }
`;

BoxOverlay.blockKey = 'rh/box-overlay';


export default BoxOverlay;
