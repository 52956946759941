import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rgba } from 'polished';

import { Colours, ImageSizes } from '@ratehub/base-ui';
import { LayoutGlobals } from '@ratehub/base-ui/src/styles';

import ImageCollectionPropType from '../definitions/ImageCollectionPropType';
import VerticalSpacing from '../definitions/VerticalSpacing';
import LayoutRow from './LayoutRow';
import CallToActionBlock from './CallToActionBlock';


const VARIANTS = {
    LIGHT: 'light',
    DARK: 'dark',
};

const BACKGROUND_GRADIENT_LEVELS = {
    DARK: 'dark',
    DARKER: 'darker',
};

const VERTICAL_SPACING_CLASS_MAP = {
    [VerticalSpacing.NONE]: 0,
    [VerticalSpacing.SMALL]: 4,
    [VerticalSpacing.MEDIUM]: 6,
    [VerticalSpacing.LARGE]: 8,
};

function HeroBannerWide({
    variant = VARIANTS.DARK,

    imageUrl,
    imageCollection,
    imagePositionX = 'center',
    imagePositionY = 'center',

    backgroundGradientLevel = BACKGROUND_GRADIENT_LEVELS.DARK,
    verticalPaddingBelow = VerticalSpacing.SMALL,
    className,
    ...otherProps
}) {
    const gradientAlpha = backgroundGradientLevel === BACKGROUND_GRADIENT_LEVELS.DARKER
        ? 1
        : .86;

    const layoutRowPaddingBottomInt = VERTICAL_SPACING_CLASS_MAP[verticalPaddingBelow];

    return (
        <MyContainer
            hasImage={!!(imageUrl || imageCollection)}
            imageUrl={imageUrl}
            imageCollection={imageCollection}
            imagePositionX={imagePositionX}
            imagePositionY={imagePositionY}
            gradientAlpha={gradientAlpha}
            className={className}
            data-test-name="hero-banner-wide"
        >
            <LayoutRow
                className={`rh-pt-8 rh-pb-${layoutRowPaddingBottomInt}`}
                position="relative"
            >
                <CallToActionBlock
                    className="rh-flex-grow-1"
                    isHero={true}
                    variant={variant}
                    ctaType="primary"
                    useAd={false}
                    alignment="center"
                    heroStyle="wide"
                    {...otherProps}
                />
            </LayoutRow>

            <svg
                width="100%"
                className="swoop"
                viewBox="0 0 2400 275"
                preserveAspectRatio="xMidYMax slice"
            >
                <path
                    d="M-7.966,0.567C79.31,24.829 167.298,46.498 255.881,65.441C405.32,97.397 556.396,121.678 708.315,138.217C875.184,156.385 1043.06,165.209 1210.91,164.81C1418.79,164.316 1626.63,149.646 1832.48,120.639C1957.73,102.99 2082.23,80.043 2205.54,51.853C2273.41,36.338 2340.89,19.212 2407.97,0.567L2410,0L2410,284.823L-10,284.823L-10,0L-7.966,0.567Z"
                />
            </svg>
        </MyContainer>
    );
}

HeroBannerWide.propTypes = {
    variant: PropTypes.oneOf(Object.values(VARIANTS)),

    imageUrl: PropTypes.string,
    imageCollection: ImageCollectionPropType,
    imagePositionX: PropTypes.string,
    imagePositionY: PropTypes.string,

    backgroundGradientLevel: PropTypes.oneOf(Object.values(BACKGROUND_GRADIENT_LEVELS)),
    verticalPaddingBelow: PropTypes.oneOf(Object.values(VerticalSpacing)),
    className: PropTypes.string,
};

const SHIELD_COLOUR = '#6b6b6b';

const MyContainer = styled.div`
    .swoop {
        display: block;

        position: relative;
        bottom: -1px; /* prevent seam below swooop when zooomed in/out */

        fill: ${Colours.COCONUT};
    }

    // .rh-layout-full adds some basic padding on mobile which we don't want on HeroBannerWide
    @media (max-width: ${LayoutGlobals.SIDEBAR_SWITCH_WIDTH}) {
        padding-left: 0;
        padding-right: 0;
    }

    /* Background image and shield */
    ${props => props.hasImage && `
        position: relative; /* shield positioned against this */

        background-image: url(${props.imageCollection ? extractImageURL(props.imageCollection) : props.imageUrl});
        background-position: ${props.imagePositionX} ${props.imagePositionY};
        background-repeat: no-repeat;
        background-size: cover;

        /* Shield */

        &::before {
            content: "";

            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            background-image: linear-gradient(
                to bottom,
                ${rgba(SHIELD_COLOUR, props.gradientAlpha)},
                transparent
            );

            mix-blend-mode: multiply;
        }
    `}
`;

function extractImageURL(imageCollection) {
    const imageURL = imageCollection.sizes?.[ImageSizes.XL]?.url
        || imageCollection.sizes?.[ImageSizes.FULL]?.url;

    return formatURL(imageURL, imageCollection.mime);
}

function formatURL(imageURL, mimeType) {
    return mimeType === 'image/webp'
        ? imageURL.slice(0, imageURL.length - '.webp'.length)
        : imageURL;
}

HeroBannerWide.blockKey = 'rh/hero-banner-wide';
HeroBannerWide.BACKGROUND_GRADIENT_LEVELS = BACKGROUND_GRADIENT_LEVELS;

export default HeroBannerWide;
