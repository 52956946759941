const ProductsEN = {
    MORTGAGES: 'mortgages',
    CREDIT_CARDS: 'credit-cards',
    CHEQUING: 'chequing',
    SAVINGS: 'savings',
    GICS: 'gics',
    LOANS: 'loans',
    ROBO_ADVISORS: 'robo-advisors',
    HOME_INSURANCE: 'home-insurance',
    AUTO_INSURANCE: 'auto-insurance',
    LIFE_INSURANCE: 'life-insurance',
    CRYPTO: 'crypto',
};


export default ProductsEN;
