import React from 'react';
import PropTypes from 'prop-types';

import { Config } from '@ratehub/base-ui';

import LinkBlockList from './LinkBlockList';
import LinkBlockAnchor from './LinkBlockAnchor';


function RelatedLinks({ links = [] }) {
    return (
        <LinkBlockList
            variant="sidebar"
            hasOuterPadding={false}
            maxChildren={999} // do not show "view more" button
        >
            <For
                each="link"
                of={links}
            >
                <LinkBlockAnchor
                    // No need to highlight active state in Related Links
                    isActive={false}
                    variant="sidebar"
                    key={Config.RATEHUB_BASE_URL + link.url}
                    url={Config.RATEHUB_BASE_URL + link.url}
                    title={link.title}
                    imgSrc={link.imageUrl}
                    imgAlt={link.imageAlt}
                    imgWidth={90}
                    imgHeight={90}
                    imgPosition="right"
                />
            </For>
        </LinkBlockList>
    );
}

RelatedLinks.propTypes = {
    links: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string,
            title: PropTypes.string,
            imageUrl: PropTypes.string, // Expects fully-qualified URI.
            imageAlt: PropTypes.string,
        }),
    ),
};

export default RelatedLinks;
