import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
    Paragraph,
    Sizes,
    PageBehaviourButton,
    IconMinus,
    IconPlus,
} from '@ratehub/base-ui';


const NUMBER_PER_ROW = 3;

function ImageGridContainer({
    heroTitle,
    heroDescription,
    title,
    showLessText,
    showMoreText,
    initialImageDisplayCount = 12,
    children,
    ...otherProps
}) {
    const ref = useRef();

    const [ isExpanded, setExpandedState ] = useState(false);
    const [ maxHeight, setMaxHeight ] = useState();

    const canSlice = !!children && children.slice; // Checks if children has array functions (type is Object though). In WP it's not the same object.
    const initialChildren = getChildrenToAlwaysDisplay(children);
    const hiddenChildren = getHiddenChildren(children);

    const showHeroTitleDot = !heroTitle?.slice(-1).match(/[?;!:]/);
    const showTitleDot = !title?.slice(-1).match(/[?;!:]/);

    useEffect(() => {
        setMaxHeight(getMaxHeight());
    }, [ isExpanded ]);

    function getSingleHeight() {
        return ref?.current?.clientHeight / (initialImageDisplayCount / NUMBER_PER_ROW) || 0;
    }

    function getNumberOfRows() {
        return Math.ceil(hiddenChildren.length / NUMBER_PER_ROW);
    }

    function getMaxHeight() {
        return getSingleHeight() * getNumberOfRows();
    }

    function getChildrenToAlwaysDisplay(childElements) {
        return childElements && canSlice ? childElements.slice(0, initialImageDisplayCount) : childElements;
    }

    function getHiddenChildren(childElements) {
        return childElements && canSlice ? childElements.slice(initialImageDisplayCount) : [];
    }

    return (
        <>
            <If condition={heroTitle}>
                <h1 className={classNames('rh-title-4xl', 'rh-my-2', { 'showDot': showHeroTitleDot })}>
                    {heroTitle}
                </h1>
            </If>

            <If condition={heroDescription}>
                <Paragraph
                    size="large"
                >
                    {heroDescription}
                </Paragraph>
            </If>

            <If condition={title}>
                <h2 className={classNames('rh-title-2xl', 'rh-my-1_5', { 'showDot': showTitleDot })}>
                    {title}
                </h2>
            </If>

            <ImageGridContainer.FlexContainer
                ref={ref}
                {...otherProps}
            >
                {initialChildren}
            </ImageGridContainer.FlexContainer>

            <ImageGridContainer.FlexContainer
                style={{
                    maxHeight: isExpanded ? maxHeight : '0',
                }}
                {...otherProps}
            >
                {hiddenChildren}
            </ImageGridContainer.FlexContainer>

            <ImageGridContainer.PageBehaviourButton
                isVisible={children && children.length > initialImageDisplayCount || !canSlice}
                onClick={() => setExpandedState(!isExpanded)}
                data-name="imageGridContainer-showMoreLess"
                iconComponent={isExpanded ? IconMinus : IconPlus}
            >
                {isExpanded
                    ? showLessText
                    : showMoreText}
            </ImageGridContainer.PageBehaviourButton>
        </>
    );
}

ImageGridContainer.propTypes = {
    heroTitle: PropTypes.string,
    heroDescription: PropTypes.string,
    title: PropTypes.string,
    showLessText: PropTypes.string,
    showMoreText: PropTypes.string,
    initialImageDisplayCount: PropTypes.number,
    children: PropTypes.array.isRequired,
};

const EASING_CURVE = 'cubic-bezier(0.19, 1, 0.22, 1)';

ImageGridContainer.FlexContainer = styled.ul`
    display: flex;
    flex-wrap: wrap;

    list-style: none;
    padding-left: 0;

    margin: 0 -${Sizes.SPACING.THREE_QUARTERS};
    overflow: hidden;

    transition: 1s max-height ${EASING_CURVE};
`;

ImageGridContainer.PageBehaviourButton = styled(PageBehaviourButton)`
    display: ${props => props.isVisible ? 'flex' : 'none'};

    margin-left: auto;
    margin-right: auto;

    text-transform: lowercase;
`;

ImageGridContainer.blockKey = 'rh/image-grid-container';
ImageGridContainer.requiresLayoutRow = true;

export default ImageGridContainer;
