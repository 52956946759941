import noticeError from '../functions/noticeError';


/**
 * Return a new `URL` object based on an existing `URL` or string and an object of URL parameters
 *
 * @param {string|URL} url - if a string, it must begin with http or https
 * @param {Object<string, string>} params - key/value object for additional URL parameters
 * @returns URL - note: this is an object, not a string
 * @throws {TypeError} - either from URL() directly, or by us detecting a bad url argument based on type
 * @note based on from https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams/URLSearchParams
 *
 * @example
 *      const updatedPageURL = addSearchParams(window.location.href, {
 *          iWasHere: 'true',
 *          additionalValue: '32767',
 *      }).toString();
 */
export default function addSearchParams(url, params = {}) {
    let myURL;

    if (typeof url === 'string') {
        myURL = new URL(url);
    } else if (typeof url === 'object' && url !== null && url instanceof URL) {
        myURL = url;
    } else {
        const error = new TypeError('[addSearchParams] Incompatible `url` argument provided');
        noticeError(error, {
            url,
            params,
        });

        throw error;
    }

    return new URL(
        `${myURL.origin}${myURL.pathname}?${new URLSearchParams([
            ...Array.from(myURL.searchParams.entries()),
            ...Object.entries(params),
        ])}`,
    );
}
