import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { TabContainer, TAB_CONTAINER_ORIENTATIONS } from '@ratehub/base-ui';

import HeadingPropTypes from '../definitions/HeadingPropTypes';
import HeadingBlock from './HeadingBlock';


function TabbedContentContainer({
    heading,
    orientation = TAB_CONTAINER_ORIENTATIONS.VERTICAL_ACCORDION,
    initialTabIndex = 0,
    forceRenderTabs = true,
    children,
    ...otherProps
}) {
    return (
        <Container {...otherProps}>
            <HeadingBlock
                variant="dark"
                {...heading}
            />

            <TabContainer
                orientation={orientation}
                initialTabIndex={initialTabIndex}
                forceRenderTabs={forceRenderTabs}
            >
                {children}
            </TabContainer>
        </Container>
    );
}

TabbedContentContainer.propTypes = {
    heading: HeadingPropTypes,
    orientation: PropTypes.oneOf([
        TAB_CONTAINER_ORIENTATIONS.VERTICAL_ACCORDION,
        TAB_CONTAINER_ORIENTATIONS.HORIZONTAL_DROPDOWN,
    ]),
    initialTabIndex: PropTypes.number,
    forceRenderTabs: PropTypes.bool,
    children: PropTypes.any,
};

const Container = styled.div``;

TabbedContentContainer.blockKey = 'rh/tabbed-content-container';
TabbedContentContainer.requiresLayoutRow = true;
TabbedContentContainer.hasHeading = true;

export default TabbedContentContainer;
