import React from 'react';
import PropTypes from 'prop-types';
import { Tab } from '@ratehub/base-ui';


function TabbedContentItem({ title, children, ...otherProps }) {
    return (
        <Tab
            title={title}
            {...otherProps}
        >
            {children}
        </Tab>
    );
}

TabbedContentItem.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired,
};

TabbedContentItem.blockKey = 'rh/tabbed-content-item';

export default TabbedContentItem;
