import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import {
    Colours,
    Paragraph,
    MessagePropType,
    formatPercentage,
    Sizes,
} from '@ratehub/base-ui';


// CIRCLE consts in SVG user units
const VIEWBOX_SIZE = 160;
const STROKE_WIDTH = 8;
const CIRCLE_RADIUS = (VIEWBOX_SIZE / 2) - (STROKE_WIDTH / 2);
const CONTAINER_WIDTH = '15rem';

function PercentageDialItem({
    colour = Colours.BLUEBERRY,
    percentage,
    description,
}) {
    const intl = useIntl();

    const circleRef = useRef();
    const [ totalLength, setTotalLength ] = useState();

    useEffect(() => {
        if (circleRef?.current) {
            setTotalLength(circleRef?.current.getTotalLength());
        }
    }, [ circleRef?.current ]);

    const dashArray = getDashArray(totalLength, percentage);

    return (
        <Container
            colour={colour}
            dashArray={dashArray}
        >
            <div className="dial-container">
                <svg viewBox={`0 0 ${VIEWBOX_SIZE} ${VIEWBOX_SIZE}`}>
                    {/* Would ordinarily use a <circle> for this as it's simpler
                    and less markup, etc. BUT crusty old browsers didn't support
                    getTotalLength() on basic shapes, just on <path>. So, this
                    is just a circle that's been converted to a path for
                    browser compatibility. */}
                    <path
                        className="track"
                        d="M83.908,4.099C124.044,6.14 156,39.367 156,80C156,120.633 124.044,153.86 83.908,155.901L80,156C38.054,156 4,121.946 4,80C4,38.054 38.054,4 80,4L83.908,4.099Z"
                        strokeWidth={STROKE_WIDTH}
                        ref={circleRef}
                    />

                    <If condition={totalLength && percentage > 0}>
                        <circle
                            className="value"
                            cx="50%"
                            cy="50%"
                            r={CIRCLE_RADIUS}
                            strokeWidth={STROKE_WIDTH}
                        />
                    </If>
                </svg>

                <If condition={typeof percentage === 'number'}>
                    <Paragraph
                        className="percentage"
                        message={formatPercentage(percentage, intl, 0)}
                        size="heading"
                    />
                </If>
            </div>

            <If condition={description}>
                <Paragraph
                    className="description"
                    message={description}
                    size="medium"
                />
            </If>
        </Container>
    );
}

PercentageDialItem.propTypes = {
    colour: PropTypes.oneOf(Object.values(Colours)),
    percentage: PropTypes.number,
    description: MessagePropType,
};

function getDashArray(totalLength, percentage) {
    return `${totalLength * (percentage / 100)} ${totalLength}`;
}

const Container = styled.div`
    display: inline-block;
    max-width: calc(${CONTAINER_WIDTH} + ${Sizes.SPACING.TWO} + ${Sizes.SPACING.TWO});
    margin: ${Sizes.SPACING.ONE_AND_A_HALF} ${Sizes.SPACING.TWO};

    > .dial-container {
        /* .percentage is positioned against this */
        position: relative;

        width: ${CONTAINER_WIDTH};
        height: 10rem;

        margin: 0 ${Sizes.SPACING.TWO} ${Sizes.SPACING.TWO};

        > svg {
            width: inherit;
            height: inherit;

            > .track {
                fill: ${Colours.TRANSPARENT};
                stroke: ${Colours.STONE_LIGHTEST};
            }

            > .value {
                fill: ${Colours.TRANSPARENT};
                ${props => props.colour && `
                    stroke: ${props.colour};
                `}

                stroke-linecap: round;

                ${props => props.dashArray && `
                    stroke-dasharray: ${props.dashArray};
                `}

                transform: rotate(-90deg);
                transform-origin: center;
            }

        }

        > .percentage {
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            transform: translateY(-50%);
            margin: 0;
            text-align: center;
            font-size: ${Sizes.FONTS['3XL']};

            ${props => props.colour && `
                color: ${props.colour};
            `}
        }
    }

    > .description {
        text-align: center;
        margin-top: 0;
        margin-bottom: 0;
    }
`;

PercentageDialItem.blockKey = 'rh/percentage-dial-item';

export default PercentageDialItem;
