import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sizes } from '@ratehub/base-ui';

import HeadingPropTypes from '../definitions/HeadingPropTypes';
import HeadingBlock from './HeadingBlock';


const CONTAINER_RESHAPE_WIDTH = '50rem';

function CustomerReviewContainer({ heading, children, ...otherProps }) {
    return (
        <div {...otherProps}>
            <HeadingBlock
                className="heading-block"
                isCentered
                {...heading}
            />

            <ul
                className="customer-review-container"
            >
                {children}
            </ul>
        </div>
    );
}

CustomerReviewContainer.propTypes = {
    heading: HeadingPropTypes,
    children: PropTypes.node,
};

// styles
const StyledCustomerReviewContainer = styled(CustomerReviewContainer)`
    > .heading-block {
        margin-bottom: ${Sizes.SPACING.FOUR};
    }

    > .customer-review-container {
        display: flex;
        flex-direction: row;
        justify-content: center;

        list-style-type: none;
        margin: 0;
        padding: 0;

        & > :not(:last-child) {
            margin-right: ${Sizes.SPACING.FIVE};
        }
    }

    @media screen and (max-width: 53.5rem) {
        > .customer-review-container {
            & > :not(:last-child) {
                margin-right: ${Sizes.SPACING.THREE};
            }
        }
    }

    /* Stacked */
    @media screen and (max-width: ${CONTAINER_RESHAPE_WIDTH}) {
        > .customer-review-container {
            align-items: center;
            flex-direction: column;

            & > :not(:last-child) {
                margin-right: 0;
                margin-bottom: ${Sizes.SPACING.FIVE};
            }
        }
    }
`;

StyledCustomerReviewContainer.blockKey = 'rh/customer-review-container';
StyledCustomerReviewContainer.requiresLayoutRow = true;
StyledCustomerReviewContainer.hasHeading = true;

export default StyledCustomerReviewContainer;
