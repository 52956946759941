export default function getSavingsProductShape(product, isSponsoredTable) {
    return {
        name: product.name,
        id: product.ids?.primary,
        slug: product.slug,
        provider: {
            name: product.provider?.name,
            logo: product.provider?.logo,
            slug: product.provider?.slug,
        },

        detailsURL: product.urls?.details,
        applyURL: product.urls?.apply_redirect,

        isMonetized: product.monetized,
        isSponsored: isSponsoredTable
            ? !!product.sponsored?.table
            : !!product.sponsored?.global,

        interest: product.interest?.default?.map(interest => ({
            rate: interest.rate,
            days: interest.duration,
            from: interest.balance?.minimum || 0,
            to: interest.balance?.maximum,
        })),
        interestRate: product.interest?.calculated?.default_rate,

        firstYearPromoReturn: product.interest?.calculated?.first_year_bonus_return,
        firstYearDefaultReturn: product.interest?.calculated?.first_year_default_return,
        firstYearReturn: product.interest?.calculated?.first_year_return,

        bonusInterest: product.interest?.calculated?.bonus_rate
            ? {
                rate: product.interest.calculated.bonus_rate,
                expiry: {
                    mode: product.interest?.bonus?.expiry?.type,
                    // either .days or .date will be empty base on .mode
                    days: product.interest?.bonus?.expiry?.days,
                    date: product.interest?.bonus?.expiry?.date,
                },
            }
            : null,

        transactionFee: product.fees?.transaction,
        eTransferFee: product.fees?.e_transfer,

        interestCompoundTerm: product.interest?.compounded,
        interestPaidTerm: product.interest?.paid,
        waiveMonthlyFeeBalance: product.minimum_balances?.rebate,
        minimumBalance: product.minimum_balances?.account,
        monthlyFee: product.fees?.monthly,
        isCDIC: product.cdic,
        isPFAWinner: product.pfa_winner,

        interestNotes: Array.isArray(product.interest?.notes)
            ? product.interest?.notes
            : [],
        restrictions: product.eligibility,
        freeMonthlyTransactions: product.free_monthly_transactions,
        thirdPartyAtmFee: product.fees?.third_party_atm,
    };
}
