import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import {
    Paragraph,
    Colours,
    Sizes,
    Typography,
    LogoLetterMark,
    usePageSettings,
} from '@ratehub/base-ui';

import AuthorPropType from '../definitions/AuthorPropType';


const IMAGE_SIZE = 40;
const DEFAULT_LETTER_MARK_SIZE = 25;
const VARIANTS = {
    LIGHT: 'light',
    DARK: 'dark',
};
function AuthorStamp({
    author,
    modifiedDate,
    publishedDate,
    selectedDate,
    separator = ', ',
    variant = VARIANTS.DARK,
    ...otherProps
}) {
    const intl = useIntl();
    const pageSettings = usePageSettings();
    const date = modifiedDate || publishedDate || selectedDate;

    const AnchorHref = pageSettings?.shouldUseBlogAuthorURL
        ? author?.blogProfileURL
        : author?.profileURL;

    return (
        <MyAnchor
            as={AnchorHref ? 'a' : 'div'}
            href={AnchorHref ? AnchorHref : ''}
            data-test-name="author-stamp"
            {...otherProps}
        >
            <Choose>
                <When condition={author.imageURL}>
                    <MyImage
                        height={IMAGE_SIZE}
                        width={IMAGE_SIZE}
                        src={author.imageURL}
                        alt="" // Alt text is redundant because the author name is next to this image - it just repeats the information available
                        loading="lazy"
                    />
                </When>
                <Otherwise>
                    <MyDefaultImageContainer
                        variant={variant}
                    >
                        <MyLogoLetterMark
                            // eslint-disable-next-line max-len
                            // We actually do want the passed variant here to be the opposite due to the background of the container
                            variant={variant === VARIANTS.LIGHT
                                ? VARIANTS.DARK
                                : VARIANTS.LIGHT
                            }
                        />
                    </MyDefaultImageContainer>
                </Otherwise>
            </Choose>

            <MyAddress>
                <MyAuthor
                    rel="author"
                    size="medium"
                    weight="medium"
                    variant={variant}
                >
                    <MyAuthorName>
                        {author.name}
                    </MyAuthorName>

                    <If condition={author.jobTitle}>
                        <MyJobTitle
                            size="medium"
                        >
                            {separator}
                            {author.jobTitle}
                        </MyJobTitle>
                    </If>
                </MyAuthor>

                <If condition={date}>
                    <MyTimeStamp
                        forwardedAs="time"
                        size="small"
                        dateTime={date} // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/time
                        variant={variant}
                        message={intl.formatDate(new Date(date), {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                        })}
                        suppressHydrationWarning // due to timezone differences, it's possible that the date will be different on the client side
                    />
                </If>
            </MyAddress>
        </MyAnchor>
    );
}

AuthorStamp.propTypes = {
    author: AuthorPropType,

    modifiedDate: PropTypes.string,
    publishedDate: PropTypes.string,
    selectedDate: PropTypes.string,

    variant: PropTypes.oneOf(Object.values(VARIANTS)),

    separator: PropTypes.string,
};

const MyImage = styled.img`
    box-sizing: border-box;
    object-fit: cover;

    position: relative;

    margin: 0 auto;

    border: 2px solid ${Colours.COCONUT};
    border-radius: 50%;

    box-shadow: 0 2px 5px ${Colours.STONE};
    overflow: hidden;
`;

const STYLE_VARIANTS = {
    [VARIANTS.LIGHT]: {
        backgroundColour: Colours.COCONUT,
        borderColour: Colours.COCONUT,
    },
    [VARIANTS.DARK]: {
        backgroundColour: Colours.BLACKBERRY,
        borderColour: Colours.BLACKBERRY,
        boxShadow: `0 2px 5px ${Colours.STONE}`,
    },
};

const MyDefaultImageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;

    width: ${IMAGE_SIZE}px;
    height: ${IMAGE_SIZE}px;

    position: relative;
    left: 1px;

    background-color: ${props => STYLE_VARIANTS[props.variant].backgroundColour};

    border-width: 2px;
    border-style: solid;
    border-color: ${props => STYLE_VARIANTS[props.variant].borderColour};

    border-radius: 50%;

    box-shadow: ${props => STYLE_VARIANTS[props.variant].boxShadow};

    overflow: hidden;
`;

const MyLogoLetterMark = styled(LogoLetterMark)`
    width: ${DEFAULT_LETTER_MARK_SIZE}px;
    height: ${DEFAULT_LETTER_MARK_SIZE}px;
`;

const MyAuthor = styled(Paragraph)`
    margin-top: 0;
    margin-bottom: 0;

    line-height: 1.3;
`;

const MyAuthorName = styled.span``;

const MyJobTitle = styled.span`
    font-weight: ${Typography.WEIGHTS.REGULAR};
`;

const MyAnchor = styled.a`
    display: inline-flex;
    flex-wrap: nowrap;

    text-decoration: none;

    margin-bottom: ${Sizes.SPACING.ONE};

    ${props => props.as === 'a' && `
        &:hover,
        &:focus {
            ${MyAuthorName} {
                text-decoration: underline;
            }
        }
    `}
`;

const MyAddress = styled.address`
    display: flex;
    flex-direction: column;
    justify-content: center;

    font-style: normal;

    margin-left: ${Sizes.SPACING.THREE_QUARTERS};
`;

const MyTimeStamp = styled(Paragraph)`
    flex-basis: 100%;

    color: ${Colours.STONE_DARKEST};

    margin-top: 0;
    margin-bottom: 0;
`;

export default AuthorStamp;
