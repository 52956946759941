import Config from './Config';


const EXPERIMENTS = {
    ENABLE_GOOGLE_SIGN_IN: {
        slug: 'enable-google-sign-in',
        segmentOverride: Config.SEGMENT_ENABLE_GOOGLE_SIGN_IN,
        variations: Config.VARIATIONS_ENABLE_GOOGLE_SIGN_IN,
    },
};

export default EXPERIMENTS;
