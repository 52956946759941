import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

import { LayoutGlobals } from '@ratehub/base-ui/src/styles';
import {
    Config,
    ExperimentSegments,
    fetchExperimentSegment,
    getExperimentSegmentSynchronously,
    getExperimentDefinition,
} from '@ratehub/base-ui';


// NOTE: this code is designed to work with code in our cloudflare worker
// The initial server render will include the HTML for every segment, and Cloudflare will then drop the HTML for the segments they're not in.
// By default, we hide the contents of each segment which isn't control; Cloudflare will also adjust the visibility of their assigned container.
function ExperimentSegment({
    slug,
    segment,
    isTopLevelBlock = false,
    children,
    className,
    ...otherProps
}) {
    const [ assignedSegment, setAssignedSegment ] = useState(() => getInitialSegment(slug));

    // Once the client has loaded, if Cloudflare has NOT already pruned the experiments, transition from Control to whichever segment they're assigned.
    // There are cases (local development, preview) where Cloudflare isn't involved.
    useEffect(() => {
        if (Config.CLOUDFLARE_HAS_PRUNED_EXPERIMENTS) {
            return;
        }

        fetchExperimentSegment(slug)
            .then(value => setAssignedSegment(value));
    }, [ slug, segment ]);

    // SPECIAL CASE: skip rendering the ENTIRE block if not needed
    if (!shouldRenderChildren(segment, assignedSegment)) {
        return null;
    }

    // Add details which allow Cloudflare to dynamically adjust the HTML response, dropping all segments which the user shouldn't see.
    // NOTE: done through Cloudflare (instead of React) to improve Core Web Vitals, especially CLS.
    const containerProps = {
        ['data-experiment-name']: slug,
        ['data-experiment-segment']: segment,
        ['data-experiment-variations']: JSON.stringify(getExperimentDefinition(slug)?.variations ?? [ 0 ]),     // [0] will cause it to fallback to only show control
        key: `experiment-${slug}-segment-${segment}`,
    };

    const isVisible = shouldShowChildren(segment, assignedSegment);

    return (
        <Container
            {...containerProps}
            className={classNames({
                'is-displayed': isVisible,
                'hide-block': !isVisible,
            }, className)}
            isTopLevelBlock={isTopLevelBlock}
            {...otherProps}
        >
            {children}
        </Container>
    );
}


ExperimentSegment.propTypes = {
    slug: PropTypes.string.isRequired,
    segment: PropTypes.oneOf(Object.values(ExperimentSegments)).isRequired,
    isTopLevelBlock: PropTypes.bool,
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
};

// NOTE: opacity transition allows for smooth "fade-in" for cases where Cloudflare isn't able to prune the experiments.
const Container = styled.div`
    opacity: 0;

    &.hide-block {
        display: none;
    }

    &.is-displayed {
        opacity: 1;
        display: block;
        transition: opacity 200ms ease-in;
    }

    // SHORT TERM SHITE - At least I hope it's short term
    ${props => props.isTopLevelBlock && `
        // Added to avoid clashes with components which truly want to be full width with padding handled internally
        // This is only problematic for rh-layout-full
        &.rh-layout-full {
            @media (max-width: ${LayoutGlobals.SIDEBAR_SWITCH_WIDTH}) {
                padding-left: 0;
                padding-right: 0;
            }
        }

        > [class^=LayoutRow__Container] > .flex-content {
            padding-left: 0;
            padding-right: 0;
        }
    `}
`;

// Get the segment to use when we first render.
// Always use CONTROL on the server
function getInitialSegment(slug) {
    return typeof window !== 'undefined'
        ? getExperimentSegmentSynchronously(slug)
        : ExperimentSegment.CONTROL;
}

// If we should render our children at all
function shouldRenderChildren(segment, assignedSegment) {
    return Config.CLOUDFLARE_HAS_PRUNED_EXPERIMENTS

        // Render ONLY their assigned segment: Cloudflare already dropped the HTML of the segments they're not in.
        ? segment === assignedSegment

        // Otherwise just render everything; we will use CSS to hide the one they're not in.
        // This ensures the HTML is available for Cloudflare to drop all except the segment they're supposed to see.
        : true;
}

// If our children should be visible to the user
function shouldShowChildren(segment, assignedSegment) {
    return typeof window !== 'undefined'
        ? assignedSegment === segment                   // CLIENT: render their assigned segment
        : segment === ExperimentSegments.CONTROL;        // SERVER: always render control
}

ExperimentSegment.blockKey = 'rh/experiment-segment';

export default ExperimentSegment;
